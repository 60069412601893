
import axios from 'axios';
import VueAxios from 'vue-axios';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import Footer from '@/components/includes/Footer.vue';
import Menu from '@/components/includes/Menu/Menu.vue';
import MenuOptions from '@/components/includes/Menu/MenuOptions.vue';

@Component({
  components: {
    Footer,
    Menu,
    MenuOptions,
  },
})
export default class SelectProduct extends Vue {
  private isShow: boolean = false;
  private curingCompound: boolean = false;
  private curingCuring: boolean = false;
  private curingCuringInterior: boolean = false;
  private curingCuringExterior: boolean = false;
  private curingCuringAndSealing: boolean = false;
  private curingCuringAndSealingWaterBaseEmulsion: boolean = false;
  private curingCuringAndSealingSolventBaseEmulsion: boolean = false;

  private repairMortar: boolean = false;
  private repairMortarVerticalRepair018: boolean = false;
  private repairMortarHorizontalRepair: boolean = false;
  private repairMortarVerticalOverheadRepair: boolean = false;
  private repairMortarLightDynamicLoadingHDPrecisionGrouting: boolean = false;
  private repairMortarHDDynamicLoadingHighEarlyStrength: boolean = false;

  private groutAnchor: boolean = false;
  private groutAnchorGeneralPurposeGroutAnchoring: boolean = false;
  private groutAnchorFastSettingAnchoring: boolean = false;
  private groutAnchorIndustrialHeavyDuty: boolean = false;

  private sealer: boolean = false;
  private sealerPenetratingSealer: boolean = false;
  private sealerMembraneSealer: boolean = false;
  private sealerPenetratingSealerExteriorConcrete: boolean = false;
  private sealerPenetratingSealerExteriorConcreteHeavyDeicingSalts: boolean = false;
  private sealerMembraneSealerWaterBased: boolean = false;
  private sealerMembraneSealerSolventBased: boolean = false;

  private errorString: string = '';
  private finalString: string = '';
  private baseURL: string = 'https://www.specchemllc.com/';
  // private baseURL: string = 'https://specchem.hoenigwebdesign.com/pwa/';
  private getProduct: string = 'wp-json/specchem/v1/product/';
  private getProductURL: string = this.baseURL + this.getProduct;
  private getProductDescription: string = 'wp-json/wp/v2/products/';
  private getProductDescriptionURL: string = this.baseURL + this.getProductDescription;
  private productNotFound: boolean = false;
  private productSelected: boolean = false;
  private product: any = '';
  private productData: any = '';
  private productName: string = '';
  private productDescription: string = '';
  private chosenCategoryID: any = '';
  private competitorNameMatch: any = '';
  private CompetitorProductList: any = '';
  private productIDFound: boolean = false;
  private productID: any = '';
  private categoryName: any = '';
  private isLoading: boolean = false;
  private productCategoryLoaded: string = '';
  private SpecChemProductIDs = [
    {specChemProduct: 'Aqua Shine', ID: '70' },
    {specChemProduct: 'Bio Strip WB', ID: '72' },
    {specChemProduct: 'Crystal Shine', ID: '210' },
    {specChemProduct: 'Cure \& Seal 25', ID: '213' },
    {specChemProduct: 'Cure \& Seal 25 UV', ID: '220' },
    {specChemProduct: 'Cure \& Seal 30', ID: '226' },
    {specChemProduct: 'Cure \& Seal WB', ID: '233' },
    {specChemProduct: 'Cure \& Seal WB 25', ID: '237' },
    {specChemProduct: 'Cure \& Seal WB 30', ID: '240' },
    {specChemProduct: 'Cure Hard', ID: '243' },
    {specChemProduct: 'Deco Shine', ID: '257' },
    {specChemProduct: 'Deco Shine Matte', ID: '264' },
    {specChemProduct: 'Dry-Deck WB', ID: '267' },
    {specChemProduct: 'Duo Patch', ID: '270' },
    {specChemProduct: 'E-Cure', ID: '274' },
    {specChemProduct: 'Final Finish', ID: '278' },
    {specChemProduct: 'LithSeal Lite', ID: '294' },
    {specChemProduct: 'LithSeal SC', ID: '290' },
    {specChemProduct: 'Orange Peel', ID: '297' },
    {specChemProduct: 'Pave Cure CW', ID: '304' },
    {specChemProduct: 'Pave Cure Rez', ID: '308' },
    {specChemProduct: 'Pave Cure Rez White', ID: '315' },
    {specChemProduct: 'Pave Cure WW', ID: '321' },
    {specChemProduct: 'Precast 80', ID: '330' },
    {specChemProduct: 'Quartz Floor Hardener', ID: '339' },
    {specChemProduct: 'Rapid Flex C.J.', ID: '1056' },
    {specChemProduct: 'RepCon 928', ID: '346' },
    {specChemProduct: 'RepCon H', ID: '353' },
    {specChemProduct: 'RepCon UW Mortar', ID: '361' },
    {specChemProduct: 'RepCon V/O', ID: '364' },
    {specChemProduct: 'RevCrete', ID: '367' },
    {specChemProduct: 'SC Metallic Grout', ID: '373' },
    {specChemProduct: 'SC Multi-purpose Grout', ID: '376' },
    {specChemProduct: 'SC Precision Grout', ID: '379' },
    {specChemProduct: 'SpecBlast', ID: '520' },
    {specChemProduct: 'SpecCity White', ID: '523' },
    {specChemProduct: 'SpecEtch', ID: '527' },
    {specChemProduct: 'SpecFilm Concentrate', ID: '533' },
    {specChemProduct: 'SpecFilm RTU', ID: '537' },
    {specChemProduct: 'SpecFlow', ID: '540' },
    {specChemProduct: 'SpecGuard', ID: '544' },
    {specChemProduct: 'SpecHard', ID: '547' },
    {specChemProduct: 'SpecLevel PCT', ID: '1538' },
    {specChemProduct: 'Spec-O-Lith', ID: '551' },
    {specChemProduct: 'SpecPatch 15', ID: '920' },
    {specChemProduct: 'SpecPatch 30', ID: '554' },
    {specChemProduct: 'SpecPatch 5', ID: '926' },
    {specChemProduct: 'SpecPatch Light', ID: '923' },
    {specChemProduct: 'SpecPlug', ID: '558' },
    {specChemProduct: 'SpecPoxy 1000', ID: '562' },
    {specChemProduct: 'SpecPoxy 2000', ID: '564' },
    {specChemProduct: 'SpecPoxy 3000', ID: '568' },
    {specChemProduct: 'SpecPoxy 3000FS', ID: '571' },
    {specChemProduct: 'SpecPoxy 500', ID: '1819' },
    {specChemProduct: 'SpecPoxy Binder', ID: '574' },
    {specChemProduct: 'SpecPoxy C.J.', ID: '576' },
    {specChemProduct: 'SpecPoxy Coating', ID: '579' },
    {specChemProduct: 'SpecPoxy Grout', ID: '582' },
    {specChemProduct: 'SpecPoxy Mortar', ID: '585' },
    {specChemProduct: 'SpecPoxy Sealer', ID: '587' },
    {specChemProduct: 'SpecPrep SB', ID: '889' },
    {specChemProduct: 'SpecRez', ID: '591' },
    {specChemProduct: 'SpecRock', ID: '595' },
    {specChemProduct: 'SpecShield WB', ID: '601' },
    {specChemProduct: 'SpecSilane 40WB', ID: '609' },
    {specChemProduct: 'SpecSmooth', ID: '611' },
    {specChemProduct: 'SpecStrip', ID: '191' },
    {specChemProduct: 'SpecStrip Plus', ID: '615' },
    {specChemProduct: 'SpecStrip Supreme', ID: '619' },
    {specChemProduct: 'SpecTilt 100', ID: '628' },
    {specChemProduct: 'SpecWeld', ID: '643' },
    {specChemProduct: 'Strong Bond', ID: '646' },
    {specChemProduct: 'Surface Shine', ID: '654' },
    {specChemProduct: 'UW Grout', ID: '681' },
    {specChemProduct: 'Clean Lift 20/20', ID: '1901' },
  ];

  @Watch('$route', { immediate: true, deep: true })
   private onUrlChange(newVal: Route) {
      if (this.$route.path.includes('exterior')) {
        this.CuringCuringExterior();
      } else if (this.$route.path.includes('interior')) {
        this.CuringCuringInterior();
      } else if (this.$route.path.includes('curingcompound')) {
        this.CuringCompound();
      } else if (this.$route.path.includes('repairmortar')) {
        this.RepairMortar();
      } else if (this.$route.path.includes('groutanchor')) {
        this.GroutAnchor();
      } else if (this.$route.path.includes('sealer')) {
        this.Sealer();
      } else if (this.$route.path.includes('selectproduct')) {
        this.ResetAll();
      }
   }

  private CreateURL(pageName: any) {
    if (this.productCategoryLoaded === pageName) {
      this.productCategoryLoaded = '';
      this.$router.push('/selectproduct');
    } else {
      this.productCategoryLoaded = pageName;
      this.$router.push('/selectproduct/' + pageName);
    }
  }

  private mounted() {
    this.ResetAll();
  }

  private ResetAll() {
    this.ResetCuring();
    this.ResetRepairMortar();
    this.ResetGroutAnchor();
    this.ResetSealer();
    this.productSelected = false;
  }

  private showMenu(showMenu) {
    this.isShow = showMenu;
  }

  private ResetCuring() {
    this.curingCompound = false;
    this.sealerPenetratingSealer = false;
    this.sealerMembraneSealer = false;
    this.sealerPenetratingSealerExteriorConcrete = false;
    this.sealerPenetratingSealerExteriorConcreteHeavyDeicingSalts = false;
    this.sealerMembraneSealerWaterBased = false;
    this.sealerMembraneSealerSolventBased = false;
    this.groutAnchorIndustrialHeavyDuty = false;
    this.groutAnchorFastSettingAnchoring = false;
    this.groutAnchorGeneralPurposeGroutAnchoring = false;
    this.repairMortarVerticalRepair018 = false;
    this.repairMortarHorizontalRepair = false;
    this.repairMortarVerticalOverheadRepair = false;
    this.curingCuringAndSealingWaterBaseEmulsion = false;
    this.curingCuringAndSealingSolventBaseEmulsion = false;
    this.curingCuringInterior = false;
    this.curingCuringExterior = false;
    this.curingCuringAndSealing = false;
    this.curingCuring = false;
  }

  private CuringCompound() {
    this.ResetCuring();
    this.groutAnchor = false;
    this.sealer = false;
    this.repairMortar = false;
    this.curingCompound = true;
  }

  private CuringCuring() {
    this.ResetCuring();
    this.curingCuring = true;
  }

  private CuringCuringAndSealing() {
    this.ResetCuring();
    this.curingCuringAndSealing = true;
  }

  private CuringCuringExterior() {
    this.ResetCuring();
    this.curingCuringExterior = true;
  }

  private CuringCuringInterior() {
    this.ResetCuring();
    this.curingCuringInterior = true;
  }

  private CuringCuringAndSealingSolventBaseEmulsion() {
    this.ResetCuring();
    this.curingCuringAndSealingSolventBaseEmulsion = true;
  }

  private CuringCuringAndSealingWaterBaseEmulsion() {
    this.ResetCuring();
    this.curingCuringAndSealingWaterBaseEmulsion = true;
  }

  private ResetRepairMortar() {
    this.repairMortar = false;
    this.sealerPenetratingSealer = false;
    this.sealerMembraneSealer = false;
    this.sealerPenetratingSealerExteriorConcrete = false;
    this.sealerPenetratingSealerExteriorConcreteHeavyDeicingSalts = false;
    this.sealerMembraneSealerWaterBased = false;
    this.sealerMembraneSealerSolventBased = false;
    this.groutAnchorIndustrialHeavyDuty = false;
    this.groutAnchorFastSettingAnchoring = false;
    this.groutAnchorGeneralPurposeGroutAnchoring = false;
    this.repairMortarVerticalRepair018 = false;
    this.repairMortarHorizontalRepair = false;
    this.repairMortarVerticalOverheadRepair = false;
    this.curingCuringAndSealingWaterBaseEmulsion = false;
    this.curingCuringAndSealingSolventBaseEmulsion = false;
    this.curingCuringInterior = false;
    this.curingCuringExterior = false;
    this.curingCuringAndSealing = false;
    this.curingCuring = false;
  }

  private RepairMortar() {
    this.ResetRepairMortar();
    this.groutAnchor = false;
    this.sealer = false;
    this.curingCompound = false;
    this.repairMortar = true;
  }

  private RepairMortarVerticalRepair018() {
    this.ResetRepairMortar();
    this.repairMortarVerticalRepair018 = true;
  }

  private RepairMortarHorizontalRepair() {
    this.ResetRepairMortar();
    this.repairMortarHorizontalRepair = true;
  }

  private RepairMortarVerticalOverheadRepair() {
    this.ResetRepairMortar();
    this.repairMortarVerticalOverheadRepair = true;
  }

  private ResetGroutAnchor() {
    this.groutAnchor = false;
    this.sealerPenetratingSealer = false;
    this.sealerMembraneSealer = false;
    this.sealerPenetratingSealerExteriorConcrete = false;
    this.sealerPenetratingSealerExteriorConcreteHeavyDeicingSalts = false;
    this.sealerMembraneSealerWaterBased = false;
    this.sealerMembraneSealerSolventBased = false;
    this.groutAnchorIndustrialHeavyDuty = false;
    this.groutAnchorFastSettingAnchoring = false;
    this.groutAnchorGeneralPurposeGroutAnchoring = false;
    this.repairMortarVerticalRepair018 = false;
    this.repairMortarHorizontalRepair = false;
    this.repairMortarVerticalOverheadRepair = false;
    this.curingCuringAndSealingWaterBaseEmulsion = false;
    this.curingCuringAndSealingSolventBaseEmulsion = false;
    this.curingCuringInterior = false;
    this.curingCuringExterior = false;
    this.curingCuringAndSealing = false;
    this.curingCuring = false;
  }

  private GroutAnchor() {
    this.ResetGroutAnchor();
    this.repairMortar = false;
    this.sealer = false;
    this.curingCompound = false;
    this.groutAnchor = true;
  }

  private GroutAnchorIndustrialHeavyDuty() {
    this.ResetGroutAnchor();
    this.groutAnchorIndustrialHeavyDuty = true;
  }

  private GroutAnchorFastSettingAnchoring() {
    this.ResetGroutAnchor();
    this.groutAnchorFastSettingAnchoring = true;
  }

  private GroutAnchorGeneralPurposeGroutAnchoring() {
    this.ResetGroutAnchor();
    this.groutAnchorGeneralPurposeGroutAnchoring = true;
  }

  private ResetSealer() {
    this.sealer = false;
    this.sealerPenetratingSealer = false;
    this.sealerMembraneSealer = false;
    this.sealerPenetratingSealerExteriorConcrete = false;
    this.sealerPenetratingSealerExteriorConcreteHeavyDeicingSalts = false;
    this.sealerMembraneSealerWaterBased = false;
    this.sealerMembraneSealerSolventBased = false;
    this.groutAnchorIndustrialHeavyDuty = false;
    this.groutAnchorFastSettingAnchoring = false;
    this.groutAnchorGeneralPurposeGroutAnchoring = false;
    this.repairMortarVerticalRepair018 = false;
    this.repairMortarHorizontalRepair = false;
    this.repairMortarVerticalOverheadRepair = false;
    this.curingCuringAndSealingWaterBaseEmulsion = false;
    this.curingCuringAndSealingSolventBaseEmulsion = false;
    this.curingCuringInterior = false;
    this.curingCuringExterior = false;
    this.curingCuringAndSealing = false;
    this.curingCuring = false;
  }

  private Sealer() {
    this.ResetSealer();
    this.repairMortar = false;
    this.curingCompound = false;
    this.groutAnchor = false;
    this.sealer = true;
  }

  private SealerPenetratingSealer() {
    this.ResetSealer();
    this.sealerPenetratingSealer = true;
  }

  private SealerMembraneSealer() {
    this.ResetSealer();
    this.sealerMembraneSealer = true;
  }

  private SealerPenetratingSealerExteriorConcrete() {
    this.ResetSealer();
    this.sealerPenetratingSealerExteriorConcrete = true;
  }

  private SealerPenetratingSealerExteriorConcreteHeavyDeicingSalts() {
    this.ResetSealer();
    this.sealerPenetratingSealerExteriorConcreteHeavyDeicingSalts = true;
  }

  private SealerMembraneSealerWaterBased() {
    this.ResetSealer();
    this.sealerMembraneSealerWaterBased = true;
  }

  private SealerMembraneSealerSolventBased() {
    this.ResetSealer();
    this.sealerMembraneSealerSolventBased = true;
  }

  private GetSpecChemProduct(specChemProductName: any) {
    const SpecChemProductName = this.SpecChemProductIDs.find((e) => e.specChemProduct === specChemProductName);
    if (SpecChemProductName === undefined) {
        throw new Error('One of the params must be provided.');
    } else if (SpecChemProductName !== undefined) {
        this.productIDFound = true;
        this.categoryName = specChemProductName;
        const SpecChemProductID = SpecChemProductName.ID;
        this.GetProduct(SpecChemProductID, specChemProductName);
    }
  }

  private GetProduct(productID: any, productName: any) {
    this.productSelected = true;
    this.isLoading = true;
    let circlePosition = 1;
    this.productName = productName;
    this.GetProductDescription(productID);

    axios.get(this.getProductURL + productID)
      .then((response: any) => {
        this.productData = response.data;
        if (this.productData) {
          this.productData.forEach((item, index) => {
            if (item.meta_key === 'video_link_1') {
              item.Name = 'Watch Video';
              item.Link = item.meta_value;
            }
          });

          this.productData.forEach((item, index) => {
            if (item.Link === null) {
              this.productData.splice(index, 1);
            }
          });

          this.productData.forEach((element) => {
            element.circlePosition = circlePosition++;
            const strLink = element.Link;
            element.Link = strLink.replace('http://', 'https://');
          });
          this.productNotFound = false;
        } else {
          this.productNotFound = true;
        }
      })
      .catch((error: any) => {
        // handle error
        if (error.response) {
            /*
            * The request was made and the server responded with a
            * status code that falls out of the range of 2xx
            */
            this.errorString = 'Data: ' + error.data;
            this.errorString += '<br> Status: ' + error.status + '<br> Header: ' + error.headers + '<br>';
        } else if (error.request) {
            /*
            * The request was made but no response was received, `error.request`
            * is an instance of XMLHttpRequest in the browser and an instance
            * of http.ClientRequest in Node.js
            */
            this.errorString = 'Request Error occured: ' + error.request + '<br>';
        } else {
            // Something happened in setting up the request and triggered an Error
            this.errorString = 'Else Error occured: ' + error.message + '<br>';
        }
      })
      .then(() => {
        // always executed
        this.productSelected = true;
        this.finalString = 'Done processing!';
        this.isLoading = false;
      });
  }

  private GetProductDescription(productID: any) {
    axios.get(this.getProductDescriptionURL + productID)
      .then((response: any) => {
        this.productDescription = response.data.description;
      })
      .catch((error: any) => {
        // handle error
        if (error.response) {
            /*
            * The request was made and the server responded with a
            * status code that falls out of the range of 2xx
            */
            this.errorString = 'Data: ' + error.data;
            this.errorString += '<br> Status: ' + error.status + '<br> Header: ' + error.headers + '<br>';
        } else if (error.request) {
            /*
            * The request was made but no response was received, `error.request`
            * is an instance of XMLHttpRequest in the browser and an instance
            * of http.ClientRequest in Node.js
            */
            this.errorString = 'Request Error occured: ' + error.request + '<br>';
        } else {
            // Something happened in setting up the request and triggered an Error
            this.errorString = 'Else Error occured: ' + error.message + '<br>';
        }
      })
      .then(() => {
        // always executed
        this.isLoading = false;
        this.productSelected = true;
        this.finalString = 'Done processing!';
      });
  }
}
