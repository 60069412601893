<template>
  <div class="DOTApprovalsMain">
    <DOTApprovals />
  </div>
</template>

<script>
// @ is an alias to /src
import DOTApprovals from '@/components/DOTApprovals.vue';

export default {
  name: 'DOT-Approvals',
  components: {
    DOTApprovals,
  },
};
</script>
