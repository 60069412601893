<template>
  <div class="crossoverchartMain">
    <Crossover />
  </div>
</template>

<script>
// @ is an alias to /src
import Crossover from '@/components/Crossover.vue';

export default {
  name: 'CrossOver-Charts',
  components: {
    Crossover,
  },
};
</script>