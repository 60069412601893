
import axios from 'axios';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import Footer from '@/components/includes/Footer.vue';
import Menu from '@/components/includes/Menu/Menu.vue';
import MenuOptions from '@/components/includes/Menu/MenuOptions.vue';
import Product from '@/models/productModel.vue';

@Component({
    components: {
        Footer,
        Menu,
        MenuOptions,
    },
})
export default class Productlibrary extends Vue {
    @Prop() private msg!: string;
    private errorString: string = '';
    private finalString: string = '';
    private productCategoriesFound: boolean = false;
    private productCategories: any[] = [];
    private productCategoriesData: any[] = [];
    private baseURL: string = 'https://us-central1-specchem-pwa.cloudfunctions.net/';
    private productsProxy: string = 'productsProxy';
    private categoriesProxy: string = 'categoryProxy?per_page=100';
    private categorySelected: boolean = false;
    private productsFound: boolean = false;
    private products: any[] = [];
    private productsData: any[] = [];
    private productFound: boolean = false;
    private productSelected: boolean = false;
    private product: any = {};
    private productData: any = {};
    private productName: string = '';
    private productID: string = '';
    private productDescription: string = '';
    private isShow: boolean = false;
    private chosenCategoryID: string = '';
    private taxonomyID: string = '';
    private categoryName: string = '';
    private reload: boolean = false;
    private isLoading: boolean = false;

    private getCategoriesURL() {
        console.log('Getting categories');
        return this.baseURL + this.categoriesProxy;
    }

    private getCategoryProductsURL(categoryId: string) {
        return this.baseURL + this.productsProxy + '?category=' + categoryId + '&per_page=100';
    }

    private getProductURL(productID: any) {
        return this.baseURL + this.productsProxy + '?productId=' + productID + '&per_page=100';
    }

    @Watch('$route', { immediate: true, deep: true })
    private onUrlChange(newVal: Route) {
        console.log('URL has changed', newVal);
        if (this.$route.path.includes('productid')) {
            this.GetProduct(this.$route.params.id, this.$route.params.title);
        } else if (this.$route.path.includes('productlibrary')) {
            this.GetCategories();
        }
    }

    private showMenu(showMenu: boolean) {
        console.log('showMenu called with', showMenu);
        this.isShow = showMenu;
    }

    private mounted() {
        console.log('Component has been mounted');
        this.GetCategories();
    }

    private GetCategories() {
        this.productCategoriesFound = true;
        this.isLoading = true;
        this.productSelected = false;
        axios.get(this.getCategoriesURL())
            .then((response: any) => {
                this.isLoading = false;
                this.productCategoriesData = response.data
                    .filter((category: any) => category.name.toLowerCase() !== 'uncategorized')
                    .map((category: any) => ({
                        ...category,
                        showProducts: false,
                        productsData: [],
                    }));
                console.log('Updated productCategoriesData', this.productCategoriesData);
            })
            .catch((error: any) => {
                this.handleApiError(error);
            })
            .then(() => {
                this.finalString = 'Done processing!';
            });
    }

    private async GetProductsByCategory(category: any) {
        console.log('Getting products by category', category);
        console.log('category:', category);

        this.productsFound = false;
        if (category.id === this.chosenCategoryID) {
            this.taxonomyID = '';
            return;
        }
        this.chosenCategoryID = category.id;
        this.categoryName = category.name;

        // Start loading
        this.isLoading = true;

        try {
            const response = await axios.get(this.getCategoryProductsURL(category.id));
            this.productsFound = true;
            category.productsData = response.data;
            category.productsData.sort((a, b) => a.name.localeCompare(b.name));
            console.log('Updated productsData', category.productsData);
            console.log('productsData:', category.productsData);

            this.$nextTick(() => {
                if (category.productsData && category.productsData.length > 0) {
                    this.taxonomyID = category.productsData[0].id;
                }
                this.Scroll(category.id);
            });
        } catch (error) {
            console.log('API error:', error);
            this.handleApiError(error);
        } finally {
            console.log('Done processing!');
            this.finalString = 'Done processing!';

            // Finish loading
            this.isLoading = false;
        }

        console.log('productsFound:', this.productsFound);
        console.log('productSelected:', this.productSelected);
        console.log('chosenCategoryID == taxonomyID:', this.chosenCategoryID == this.taxonomyID);
    }

    private UpdateProductURL(product: any) {
        console.log('Updating product URL', product);
        console.log('Selected product: ', product);
        this.productSelected = true;
        console.log('productSelected set to true');
        const productName = product && product.name ? product.name.replace('/', '').replace(/[^ -~]+/g, '') : 'undefined-product';
        this.$router.push(`${this.$route.path}/productid/${product.id}/${productName}`);
    }

    private async GetProduct(productID: any, productName: any) {
        this.productSelected = false;
        console.log('Starting GetProduct', productID, productName);
        try {
            const response = await axios.get(this.getProductURL(productID));
            console.log('Received response', response);
            this.productData = [response.data];
            if (this.productData && this.productData[0]) {
                console.log(this.productData[0]);
                this.productName = this.productData[0].name;
                this.productDescription = this.productData[0].short_description;

                // Extract the datasheet URLs and assign them to their respective circles
                const acf = this.productData[0].acf;
                this.productData[0].sheets = [
                    { name: 'Tech Data Sheet', link: acf.tech_data_sheet },
                    { name: 'Safety Data Sheet', link: acf.safety_data_sheet },
                    { name: 'Sell Sheet', link: acf.sell_sheet },
                    { name: 'Spanish TDS', link: acf.tds_esp },
                ].filter(sheet => sheet.link) // Exclude sheets without a link
                    .map((sheet, index) => ({ ...sheet, circlePosition: index + 1 })); // Assign circlePosition to each sheet

                this.productSelected = true;
            } else {
                console.error('No product data returned from API');
            }
        } catch (error) {
            console.log('Error during GetProduct', error);
            this.handleApiError(error);
        } finally {
            console.log('Done processing product!');
        }
    }

    private handleApiError(error: any) {
        console.log('API error:', error);
        this.errorString = 'There was an API error';
    }

    private Scroll(id: any) {
        const element = document.getElementById('productCategories' + id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }

    }

    private toggleCategory(category: any) {
        console.log('toggleCategory called with', category);
        if (category.id === this.chosenCategoryID) {
            category.showProducts = !category.showProducts;
        } else {
            // immediately show the products for this category
            category.showProducts = true;

            // only load products if they have not been loaded yet
            if (!category.productsData || category.productsData.length === 0) {
                this.GetProductsByCategory(category);
            } else {
                this.chosenCategoryID = category.id;
                this.categoryName = category.name;
            }
        }
    }
