
import axios from 'axios';
import VueAxios from 'vue-axios';
import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuOptions from '@/components/includes/Menu/MenuOptions.vue';

@Component({
  components: {
    MenuOptions,
  },
})
export default class Menu extends Vue {
  private closeModal() {
    this.$emit('showMenu', false);
  }
}
