
import axios from 'axios';
import VueAxios from 'vue-axios';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import Footer from '@/components/includes/Footer.vue';
import Menu from '@/components/includes/Menu/Menu.vue';
import MenuOptions from '@/components/includes/Menu/MenuOptions.vue';
import Product from '@/models/productModel.vue';

@Component({
    components: {
        Footer,
        Menu,
        MenuOptions,
    },
})
export default class Productlibrary extends Vue {
    @Prop() private msg!: string;
    private errorString: string = '';
    private finalString: string = '';
    private productCategoriesFound: boolean = false;
    private productCategories: any = '';
    private productCategoriesData: any = '';
    private baseURL: string = 'https://specchem.com/';
    private pageAPIURL: string = 'wp-json/wp/v2/pages';
    private postAPIURL: string = 'wp-json/wp/v2/posts';
    private getCategories: string = 'wp-json/wp/v2/product_categories/';
    private getCategoriesURL: string = this.baseURL + this.getCategories;
    private categorySelected: boolean = false;

    private getCategoryProducts: string = 'wp-json/specchem/v1/productsforcategory/';
    private getCategoryProductsURL: string = this.baseURL + this.getCategoryProducts;
    private productsFound: boolean = false;
    private products: any = '';
    private productsData: any = '';

    private getProduct: string = 'wp-json/wp/v2/product/';
    private getProductURL: string = this.baseURL + this.getProduct;
    private productFound: boolean = false;
    private productSelected: boolean = false;
    private product: any = '';
    private productData: any = '';
    private productName: string = '';
    private productDescription: string = '';
    private isShow: boolean = false;
    private chosenCategoryID: any = '';
    private competitorNameMatch: any = '';
    private CompetitorProductList: any = '';
    private productIDFound: boolean = false;
    private productID: any = '';
    private isLoading: boolean = false;

    private SpecChemProductIDs = [
        { specChemProduct: 'All Shield EX', ID: '7584' },
        { specChemProduct: 'All Shield WB', ID: '7839' },
        { specChemProduct: 'Aqua Shine', ID: '7522' },
        { specChemProduct: 'Berry Clean', ID: '7498' },
        { specChemProduct: 'Bio Strip WB', ID: '7920' },
        { specChemProduct: 'Bio Strip WB Concentrate', ID: '7917' },
        { specChemProduct: 'C-Set', ID: '7495' },
        { specChemProduct: 'Clean Lift 20/20', ID: '7921' },
        { specChemProduct: 'Concrete Glue', ID: '7544' },
        { specChemProduct: 'Crystal Rez', ID: '7504' },
        { specChemProduct: 'Crystal Shine', ID: '7523' },
        { specChemProduct: 'Crystal Shine Matte', ID: '7524' },
        { specChemProduct: 'Cure & Seal 25', ID: '7505' },
        { specChemProduct: 'Cure & Seal 25 EX', ID: '7506' },
        { specChemProduct: 'Cure & Seal 25 UV', ID: '7507' },
        { specChemProduct: 'Cure & Seal 25 UV EX', ID: '7508' },
        { specChemProduct: 'Cure & Seal 30', ID: '7509' },
        { specChemProduct: 'Cure & Seal 30 EX', ID: '7510' },
        { specChemProduct: 'Cure & Seal EX', ID: '7511' },
        { specChemProduct: 'Cure & Seal WB', ID: '7512' },
        { specChemProduct: 'Cure & Seal WB 25', ID: '7513' },
        { specChemProduct: 'Cure & Seal WB 30', ID: '7514' },
        { specChemProduct: 'Cure Hard', ID: '7515' },
        { specChemProduct: 'Cure Shield', ID: '7516' },
        { specChemProduct: 'Cure Shield EX', ID: '7517' },
        { specChemProduct: 'Cure Shield WB', ID: '9179' },
        { specChemProduct: 'Deco Liquid Release', ID: '7922' },
        { specChemProduct: 'Deco Shine', ID: '7525' },
        { specChemProduct: 'Deco Shine Brown EX', ID: '7923' },
        { specChemProduct: 'Deco Shine Matte', ID: '7526' },
        { specChemProduct: 'Dry Deck WB', ID: '8176' },
        { specChemProduct: 'Duo Patch', ID: '7562' },
        { specChemProduct: 'E-Cure', ID: '7518' },
        { specChemProduct: 'EZ Clean & Release', ID: '7924' },
        { specChemProduct: 'Final Finish', ID: '7563' },
        { specChemProduct: 'Form Seasoning WB', ID: '7925' },
        { specChemProduct: 'IntraCon', ID: '7564' },
        { specChemProduct: 'IntraFilm', ID: '9180' },
        { specChemProduct: 'K-Set', ID: '7496' },
        { specChemProduct: 'LithSeal Lite', ID: '7549' },
        { specChemProduct: 'LithSeal SC', ID: '7550' },
        { specChemProduct: 'MVM Prime 5000', ID: '9181' },
        { specChemProduct: 'Max-A-Patch ACP', ID: '7579' },
        { specChemProduct: 'Max-A-Seal Asphalt Paint', ID: '7580' },
        { specChemProduct: 'Max-A-Seal DP', ID: '7581' },
        { specChemProduct: 'Max-A-Seal WP', ID: '7582' },
        { specChemProduct: 'Max-A-Tack TTC', ID: '7583' },
        { specChemProduct: 'Orange Peel', ID: '7499' },
        { specChemProduct: 'OverCrete', ID: '7565' },
        { specChemProduct: 'Pave Cure AMS', ID: '7554' },
        { specChemProduct: 'Pave Cure CW', ID: '7555' },
        { specChemProduct: 'Pave Cure CW W/Dye TX Type I', ID: '7926' },
        { specChemProduct: 'Pave Cure Rez', ID: '7927' },
        { specChemProduct: 'Pave Cure Rez White', ID: '7556' },
        { specChemProduct: 'Pave Cure Rez White - TX Type II', ID: '7557' },
        { specChemProduct: 'Pave Cure WW', ID: '7558' },
        { specChemProduct: 'Pave Cure WW-300', ID: '7560' },
        { specChemProduct: 'Poly Fix', ID: '7928' },
        { specChemProduct: 'PolyCure', ID: '7519' },
        { specChemProduct: 'Por-Rok', ID: '7536' },
        { specChemProduct: 'Por-Rok Aqua Plug', ID: '8177' },
        { specChemProduct: 'Por-Rok Concrete Patch', ID: '8178' },
        { specChemProduct: 'Precast 80', ID: '7929' },
        { specChemProduct: 'Pro Plug 45', ID: '7566' },
        { specChemProduct: 'Quartz Floor Hardener', ID: '7535' },
        { specChemProduct: 'Rapid Flex 90', ID: '7930' },
        { specChemProduct: 'Rapid Flex CJ', ID: '7931' },
        { specChemProduct: 'Rapid Flex Colorant System', ID: '9182' },
        { specChemProduct: 'RepCon 928', ID: '7567' },
        { specChemProduct: 'RepCon 928 FS', ID: '7568' },
        { specChemProduct: 'RepCon H', ID: '7569' },
        { specChemProduct: 'RepCon H-350', ID: '7570' },
        { specChemProduct: 'RepCon UW Mortar', ID: '7571' },
        { specChemProduct: 'RepCon V/O', ID: '7572' },
        { specChemProduct: 'RevCrete', ID: '7573' },
        { specChemProduct: 'SC Concrete Finish', ID: '7574' },
        { specChemProduct: 'SC Cure 500', ID: '7520' },
        { specChemProduct: 'SC Foundation Coating', ID: '7575' },
        { specChemProduct: 'SC Metallic Grout', ID: '7537' },
        { specChemProduct: 'SC Multi-Purpose Grout', ID: '7538' },
        { specChemProduct: 'SC Multi-Purpose Sealer', ID: '7932' },
        { specChemProduct: 'SC Precision Grout', ID: '7539' },
        { specChemProduct: 'SC Precision Grout HS', ID: '7540' },
        { specChemProduct: 'Sealer Renew', ID: '7500' },
        { specChemProduct: 'Sleek 1000 SLU', ID: '7933' },
        { specChemProduct: 'Solvent 100', ID: '7501' },
        { specChemProduct: 'Spec CJ Guard', ID: '7934' },
        { specChemProduct: 'Spec-O-Lith', ID: '7551' },
        { specChemProduct: 'SpecBlast', ID: '7502' },
        { specChemProduct: 'SpecCity White', ID: '7561' },
        { specChemProduct: 'SpecEtch', ID: '7935' },
        { specChemProduct: 'SpecEtch Deep', ID: '7936' },
        { specChemProduct: 'SpecFilm Concentrate', ID: '7937' },
        { specChemProduct: 'SpecFilm RTU', ID: '7938' },
        { specChemProduct: 'SpecFlex', ID: '7497' },
        { specChemProduct: 'SpecFlow', ID: '7829' },
        { specChemProduct: 'SpecGuard', ID: '7552' },
        { specChemProduct: 'SpecHard - Intraseal', ID: '7553' },
        { specChemProduct: 'SpecLevel PCT', ID: '7939' },
        { specChemProduct: 'SpecLevel PCT-AGG', ID: '7576' },
        { specChemProduct: 'SpecPatch 15', ID: '7942' },
        { specChemProduct: 'SpecPatch 30', ID: '7943' },
        { specChemProduct: 'SpecPatch 5', ID: '7941' },
        { specChemProduct: 'SpecPatch Light', ID: '7940' },
        { specChemProduct: 'SpecPlate Floor Topping', ID: '7944' },
        { specChemProduct: 'SpecPlate Hardener', ID: '7945' },
        { specChemProduct: 'SpecPlate NOX', ID: '7946' },
        { specChemProduct: 'SpecPlug', ID: '7947' },
        { specChemProduct: 'SpecPoxy 1000', ID: '7958' },
        { specChemProduct: 'SpecPoxy 2000', ID: '7955' },
        { specChemProduct: 'SpecPoxy 2000 LPL', ID: '7954' },
        { specChemProduct: 'SpecPoxy 3000', ID: '7957' },
        { specChemProduct: 'SpecPoxy 3000 FS', ID: '7956' },
        { specChemProduct: 'SpecPoxy 500', ID: '7953' },
        { specChemProduct: 'SpecPoxy Binder', ID: '7948' },
        { specChemProduct: 'SpecPoxy CJ', ID: '7949' },
        { specChemProduct: 'SpecPoxy Coating', ID: '7950' },
        { specChemProduct: 'SpecPoxy Grout', ID: '7977' },
        { specChemProduct: 'SpecPoxy Grout HS', ID: '7578' },
        { specChemProduct: 'SpecPoxy Mortar', ID: '7951' },
        { specChemProduct: 'SpecPoxy Sealer', ID: '7952' },
        { specChemProduct: 'SpecPrep SB', ID: '7962' },
        { specChemProduct: 'SpecPrime', ID: '7545' },
        { specChemProduct: 'SpecRez', ID: '7521' },
        { specChemProduct: 'SpecRock', ID: '7541' },
        { specChemProduct: 'SpecShield SB', ID: '7959' },
        { specChemProduct: 'SpecShield WB', ID: '7960' },
        { specChemProduct: 'SpecShield WB Extra', ID: '7961' },
        { specChemProduct: 'SpecShot Plus', ID: '7963' },
        { specChemProduct: 'SpecSilane 100', ID: '7585' },
        { specChemProduct: 'SpecSilane 20 WB', ID: '7964' },
        { specChemProduct: 'SpecSilane 40', ID: '8179' },
        { specChemProduct: 'SpecSilane 40 WB', ID: '7965' },
        { specChemProduct: 'SpecSmooth', ID: '7966' },
        { specChemProduct: 'SpecStrip', ID: '7967' },
        { specChemProduct: 'SpecStrip Plus', ID: '7968' },
        { specChemProduct: 'SpecStrip Supreme', ID: '7969' },
        { specChemProduct: 'SpecStrip Ultra Blue', ID: '7970' },
        { specChemProduct: 'SpecStrip VOC 100', ID: '7971' },
        { specChemProduct: 'SpecStrip WB', ID: '7972' },
        { specChemProduct: 'SpecTilt 100', ID: '7975' },
        { specChemProduct: 'SpecTilt EX', ID: '7973' },
        { specChemProduct: 'SpecTilt WB', ID: '7974' },
        { specChemProduct: 'SpecWeld', ID: '7546' },
        { specChemProduct: 'Strong Bond', ID: '7547' },
        { specChemProduct: 'StuccoBond', ID: '7548' },
        { specChemProduct: 'Super Por-Rok', ID: '7542' },
        { specChemProduct: 'Super SpecPlug', ID: '7976' },
        { specChemProduct: 'Surface Grip', ID: '7527' },
        { specChemProduct: 'Surface Shine', ID: '7528' },
        { specChemProduct: 'Surface Shine Brown', ID: '9184' },
        { specChemProduct: 'Surface Shine Brown EX', ID: '7529' },
        { specChemProduct: 'Surface Shine EX', ID: '7531' },
        { specChemProduct: 'Surface Shine Gray', ID: '7530' },
        { specChemProduct: 'Surface Shine Gray EX', ID: '7532' },
        { specChemProduct: 'Surface Shine WB', ID: '7534' },
        { specChemProduct: 'Total Patch', ID: '8180' },
        { specChemProduct: 'TriAct', ID: '7978' },
        { specChemProduct: 'UW Grout', ID: '7543' },
        { specChemProduct: 'V-Release', ID: '7981' },
        { specChemProduct: 'Versa Etch', ID: '7979' },
        { specChemProduct: 'Xylene', ID: '7503' }
    ];

    private CompetitorNames = [
        { name: 'Adhesives Tech' },
        { name: 'Ardex' },
        { name: 'BASF' },
        { name: 'ChemMasters' },
        { name: 'Dayton' },
        { name: 'Euclid/Tamms' },
        { name: 'Five Star' },
        { name: 'Glaze N Seal' },
        { name: 'Kaufman' },
        { name: 'Lambert' },
        { name: 'L&M' },
        { name: 'Mapei' },
        { name: 'Metzger-McGuire' },
        { name: 'Nox-Crete' },
        { name: 'Prosoco' },
        { name: 'Rapid Set' },
        { name: 'Sika' },
        { name: 'TK Products' },
        { name: 'Vexcon' },
        { name: 'W.R. Meadows' },
        { name: 'Miscellaneous' },
    ];

    private CompetitorProducts = [{
            {
                name: 'Adhesives Tech',
                products: [
                    { name: 'Ultrabond 1300', specChemProduct: 'SpecPoxy 3000 FS' },
                    { name: 'Crackbond LR 321', specChemProduct: 'SpecPoxy 1000' },
                    { name: 'Ultrabond 2100', specChemProduct: 'SpecPoxy 2000' },
                    { name: 'Ultrabond 2300', specChemProduct: 'SpecPoxy 3000' },
                ]
            },
            {
                name: 'Ardex',
                products: [
                    { name: 'Ardex CD', specChemProduct: 'OverCrete Coarse' },
                    { name: 'Ardex B20', specChemProduct: 'RepCon V_O' },
                    { name: 'Ardex Ardiseal Rapid Plus', specChemProduct: 'Rapid Flex CJ' },
                    { name: 'Ardex CD Fine', specChemProduct: 'OverCrete Fine' },
                    { name: 'Ardex PC-T', specChemProduct: 'SpecLevel PCT' },
                    { name: 'Ardex GPS, Ardex CP, Rapid Set Cement All', specChemProduct: 'SpecPatch 30' },
                    { name: 'Ardex OVP, SKM, CP', specChemProduct: 'SpecPatch 15' },
                    { name: 'Ardex CD', specChemProduct: 'Duo Patch' },
                    { name: 'Ardex B20', specChemProduct: 'RepCon V_O' },
                    { name: 'Ardex TWP', specChemProduct: 'Final Finish' },
                    {name: 'Ardex TWP, Ardex OVP', specChemProduct: 'SpecSmooth'
                },
                { name: 'Ardex K15, LU 100, K10, V1200', specChemProduct: 'SpecFlow' },
                { name: 'Ardex Ardifix', specChemProduct: 'Poly Fix' },
            ]
        },
        {
            name: 'BASF',
            products: [
                { name: 'Cast-Off', specChemProduct: 'SpecStrip' },
                { name: 'MasterKure CC 250 SB', specChemProduct: 'Cure & Seal 25' },
                { name: 'MasterKure CC 300 SB', specChemProduct: 'Cure & Seal 30' },
                { name: 'MasterKure CC 160 WB', specChemProduct: 'Cure & Seal WB' },
                { name: 'MasterKure CC 250 SB', specChemProduct: 'Cure & Seal 25 UV' },
                { name: 'MasterKure HD 110 WB', specChemProduct: 'Cure Hard' },
                { name: 'Lapidolith', specChemProduct: 'Spec-O-Lith' },
                { name: 'HD 300WB', specChemProduct: 'Spec-O-Lith' },
                { name: 'MasterKure HD 210 WB', specChemProduct: 'SpecHard' },
                { name: 'MasterKure ER 50', specChemProduct: 'SpecFilm Concentrate' },
                { name: 'Citrus Degreaser', specChemProduct: 'Orange Peel' },
                { name: 'White Roc 10 WB', specChemProduct: 'SpecShield WB' },
                { name: 'MasterEmaco A660', specChemProduct: 'Strong Bond' },
                { name: 'Eco Weld', specChemProduct: 'SpecWeld' },
                { name: 'MasterInject 1701', specChemProduct: 'SpecPoxy 1000' },
                { name: 'MasterEmaco ADH 330', specChemProduct: 'SpecPoxy 2000' },
                { name: 'MasterEmaco ADH 335', specChemProduct: 'SpecPoxy 3000' },
                { name: 'Rapid Gel', specChemProduct: 'SpecPoxy 3000 FS' },
                { name: 'MasterSeal CR 335, 190', specChemProduct: 'SpecPoxy CJ' },
                { name: 'MasterSeal 590', specChemProduct: 'SpecPlug' },
                { name: 'MasterSeal 350', specChemProduct: 'SpecPoxy Binder' },
                { name: 'MasterFlow 648 CP', specChemProduct: 'SpecPoxy Grout' },
                { name: 'MasterFlow GP, MasterEmaco P 160', specChemProduct: 'SpecPoxy Sealer' },
                { name: 'MasterFlow 928', specChemProduct: 'SC Precision Grout' },
                { name: 'Embeco 885', specChemProduct: 'SC Metallic Grout' },
                { name: 'MasterTop 100', specChemProduct: 'Quartz Floor Hardener' },
                { name: 'MasterEmaco N425, Sonopatch TC', specChemProduct: 'SpecPatch 30' },
                { name: 'MasterEmaco N425, Sonopatch 200', specChemProduct: 'RepCon V_O' },
                { name: 'Sonopatch 300', specChemProduct: 'Duo Patch' },
                { name: 'Gel Patch/Sonopatch 200', specChemProduct: 'RepCon V_O' },
                { name: 'MasterEmaco R310 CL, Emaco 566', specChemProduct: 'RepCon H' },
                { name: 'Lapidolith, HD 300WB', specChemProduct: 'Spec-O-Lith' },
                { name: 'Masterprotect H-400', specChemProduct: 'SpecSilane 40WB' },
                { name: 'MB Construction Grout, MasterFlow 713, MasterFlow 555', specChemProduct: 'SC Multi-purpose Grout' },
                { name: 'Masterflow 4316', specChemProduct: 'SC Precision Grout HS' },
                { name: 'MasterKure ER 50', specChemProduct: 'SpecFilm Concentrate' },
                { name: 'Master Emaco 10-61', specChemProduct: 'RepCon 928' },
                { name: 'Master Emaco 10-60', specChemProduct: 'RepCon UW Mortar' },
                { name: 'MasterFlow 110AN', specChemProduct: 'SpecRock' },
                { name: 'MasterEmaco P 160', specChemProduct: 'SpecPoxy 500' },
                { name: 'MasterTop 1213', specChemProduct: 'SpecPoxy Coating' },
            ]
        },
        {
            name: 'ChemMasters',
            products: [
                { name: 'ChemMasters Release', specChemProduct: 'SpecStrip' },
                { name: 'ChemMasters Silencure-A', specChemProduct: 'Cure Shield EX' },
                { name: 'ChemMasters Silencure-SRT', specChemProduct: 'Cure Shield WB' },
                { name: 'ChemMasters Spray-Cure & Seal 25', specChemProduct: 'Cure & Seal 25' },
                { name: 'ChemMasters Traz 25', specChemProduct: 'Cure & Seal 25 UV' },
                { name: 'ChemMasters Safe-Cure 1000', specChemProduct: 'SpecRez' },
                { name: 'ChemMasters Safe-Cure 1000', specChemProduct: 'SpecStrip Supreme' },
                { name: 'ChemMasters Safe-Cure 2000', specChemProduct: 'Pave Cure Rez White' },
                { name: 'ChemMasters Chemisil', specChemProduct: 'Cure Hard' },
                { name: 'ChemMasters Duraguard 120', specChemProduct: 'SpecPoxy Coating' },
                { name: 'ChemMasters Met-OX Grout', specChemProduct: 'SC Metallic Grout' },
                { name: 'ChemMasters Aquanil Plus', specChemProduct: 'SC Metallic Grout' },
            ]
        },
        {
            name: 'Dayton',
            products: [
                { name: 'J-1, J-1-A', specChemProduct: 'SpecStrip' },
                { name: 'J-2', specChemProduct: 'SpecStrip Plus' },
                { name: 'J-3', specChemProduct: 'SpecStrip Supreme' },
                { name: 'J-4 Bio, J-1EF, Farm Fresh XL ', specChemProduct: 'Bio Strip WB' },
                { name: 'J-20, J-22', specChemProduct: 'Cure & Seal 25' },
                { name: 'J-18, J-19', specChemProduct: 'Cure & Seal WB' },
                { name: 'J-19, J-22 WB, Cure & Seal 1315 EF', specChemProduct: 'Cure & Seal WB 25' },
                { name: 'J-22UV, J-25', specChemProduct: 'Cure & Seal 25 UV' },
                { name: 'J9-A', specChemProduct: 'SpecCity White' },
                { name: 'White Wax Cure', specChemProduct: 'Pave Cure WW' },
                { name: 'Clear Resin Cure', specChemProduct: 'Pave Cure Rez' },
                { name: 'White Resin Cure', specChemProduct: 'Pave Cure Rez White' },
                { name: 'J-13', specChemProduct: 'Cure Hard' },
                { name: 'Pentra Hard-Densifier', specChemProduct: 'LithSeal Lite' },
                { name: 'J-17', specChemProduct: 'SpecHard' },
                { name: 'J-6 WB, Maxi-Tilt E', specChemProduct: 'SpecTilt WB' },
                { name: 'J-6, Super Maxi Tilt', specChemProduct: 'SpecTilt 100' },
                { name: 'J-6 LVOC', specChemProduct: 'SpecTilt EX' },
                { name: 'Aquafilm, J-74 RTU', specChemProduct: 'SpecFilm RTU' },
                { name: 'J-74, Aquafilm Conc.', specChemProduct: 'SpecFilm Concentrate' },
                { name: 'Top Cast', specChemProduct: 'SpecEtch' },
                { name: 'J-48', specChemProduct: 'Orange Peel' },
                { name: 'J-24', specChemProduct: 'Surface Shine' },
                { name: 'J-24', specChemProduct: 'Surface Shine EX' },
                { name: 'J-25', specChemProduct: 'Deco Shine' },
                { name: 'Ultra Seal EF', specChemProduct: 'Aqua Shine' },
                { name: 'J-26 WB', specChemProduct: 'SpecShield WB' },
                { name: 'J-29', specChemProduct: 'SpecSilane 40WB' },
                { name: 'J-40', specChemProduct: 'Strong Bond' },
                { name: 'J-41', specChemProduct: 'SpecWeld' },
                { name: 'J-56', specChemProduct: 'SpecPoxy 1000' },
                { name: 'J-52, Pro-Flex', specChemProduct: 'SpecPoxy CJ' },
                { name: '#1107', specChemProduct: 'SC Multi-purpose Grout' },
                { name: 'Quartz Tuff', specChemProduct: 'Quartz Floor Hardener' },
                { name: 'Recrete, Thin Resurfacer', specChemProduct: 'SpecPatch 30' },
                { name: 'Special Patch', specChemProduct: 'Duo Patch' },
                { name: 'Architectural Finish', specChemProduct: 'SpecSmooth' },
                { name: 'Permapatch V/O', specChemProduct: 'RepCon V_O' },
                { name: 'Polyfast', specChemProduct: 'RepCon H' },
                { name: 'J-23, J-25', specChemProduct: 'Cure & Seal 30' },
                { name: 'J9 - A, Clear Cure VOC, J-7WB', specChemProduct: 'SpecRez' },
                { name: 'Pentra-Hard Guard', specChemProduct: 'SpecGuard' },
                { name: 'Pentra-Hard Densifier', specChemProduct: 'LithSeal Lite' },
                { name: 'J-29A', specChemProduct: 'SpecSilane 20WB' },
                { name: 'Pro Poxy 40 LV LM', specChemProduct: 'SpecPoxy 500' },
                { name: 'J-58, Pro Poxy 204', specChemProduct: 'SpecPoxy 2000' },
                { name: 'J-50, Pro-Poxy 300', specChemProduct: 'SpecPoxy 3000' },
                { name: 'J-51 FS, Pro-Poxy 300 Fast', specChemProduct: 'SpecPoxy 3000 FS' },
                { name: 'J-37', specChemProduct: 'SpecPoxy Coating' },
                { name: 'J-55, Pro-Poxy 2000 NS', specChemProduct: 'SpecPoxy Grout' },
                { name: 'Sure Patch, Pro-Poxy 2500', specChemProduct: 'SpecPoxy Mortar' },
                { name: 'Pro-Poxy 50-1', specChemProduct: 'SpecPoxy Sealer' },
                { name: 'Dayton Metallic Grout', specChemProduct: 'SC Metallic Grout' },
                { name: 'Anchor All, Anchoring Cement', specChemProduct: 'SpecRock' }, 
                { name: 'HD-50', specChemProduct: 'RepCon 928 FS' },
            ]
        },
        {
            name: 'Euclid/Tamms',
            products: [
                { name: 'Aqua-Cure VOX, Diamond Clear VOX', specChemProduct: 'Cure & Seal WB' },
                { name: 'Crystal Release VOC', specChemProduct: 'SpecStrip Supreme' },
                { name: 'Concrete Blaster', specChemProduct: 'SpecBlast' },
                { name: 'Concrete Surface Retarders S', specChemProduct: 'SpecEtch' },
                { name: 'Diamond Clear, Rez Seal, Super Rez Seal', specChemProduct: 'Cure & Seal 25' },
                { name: 'Euco Clean & Strip', specChemProduct: 'Orange Peel' },
                { name: 'Luster Seal, Diamond Clear', specChemProduct: 'Surface Shine' },
                { name: 'Luster Seal 350, Super Diamond Clear 350', specChemProduct: 'Surface Shine EX' },
                { name: 'Eucosil', specChemProduct: 'Cure Hard' },
                { name: 'Euco Diamond Hard', specChemProduct: 'SpecHard' },
                { name: 'Eucobar', specChemProduct: 'SpecFilm Concentrate' },
                { name: 'Euco #452 LV', specChemProduct: 'SpecPoxy 1000' },
                { name: 'Euco #452 MV', specChemProduct: 'SpecPoxy 2000' },
                { name: 'Euco #452 Gel', specChemProduct: 'SpecPoxy 3000' },
                { name: 'Euco #620 Gel, Dural Fast Set Gel', specChemProduct: 'SpecPoxy 3000 FS' },
                { name: 'Form Shield Pure', specChemProduct: 'SpecStrip' },
                { name: 'FormShield G', specChemProduct: 'SpecStrip Plus' },
                { name: 'Form-EZE Natural', specChemProduct: 'Bio Strip WB' },
                { name: 'Super Floor Coat', specChemProduct: 'Cure & Seal 30' },
                { name: 'Super Aqua-Cure VOX, Super Diamond Clear VOX', specChemProduct: 'Cure & Seal WB 25' },
                { name: 'Lusterseal 300, Super Diamond Clear', specChemProduct: 'Cure & Seal 25 UV' },
                { name: 'Kurez VOX White Pigmented', specChemProduct: 'Pave Cure WW' },
                { name: 'SurfHard', specChemProduct: 'Spec-O-Lith' },
                { name: 'Ultrasil Li+', specChemProduct: 'LithSeal Lite' },
                { name: 'Ultra Guard', specChemProduct: 'SpecGuard' },
                { name: 'Ever Clear', specChemProduct: 'Deco Shine' },
                { name: 'Everclear 350', specChemProduct: 'Crystal Shine' },
                { name: 'Lusterseal 300 WB, Everclear VOX', specChemProduct: 'Aqua Shine' },
                { name: 'Baracade Silane 40', specChemProduct: 'SpecSilane 40WB' },
                { name: 'Flexolith', specChemProduct: 'SpecPoxy Binder' },
                { name: 'Dural 50', specChemProduct: 'SpecPoxy 500' },
                { name: 'Duralkote 240', specChemProduct: 'SpecPoxy Coating' },
                { name: 'Euco 700', specChemProduct: 'SpecPoxy CJ' },
                { name: 'Euco QWIKjoint UVR', specChemProduct: 'Rapid Flex CJ' },
                { name: 'E-3F, E-3G, E-3HP, Polygrout', specChemProduct: 'SpecPoxy Grout' },
                { name: 'Duralflex Fastpatch', specChemProduct: 'SpecPoxy Mortar' },
                { name: 'Dural 50 LM', specChemProduct: 'SpecPoxy Sealer' },
                { name: 'NS Grout/NC Grout', specChemProduct: 'SC Multi-purpose Grout' },
                { name: 'Hi-Flow Grout', specChemProduct: 'SC Precision Grout' },
                { name: 'NS Metallic Grout', specChemProduct: 'SC Metallic Grout' },
                { name: 'Tremie Grout', specChemProduct: 'UW Grout' },
                { name: 'Surflex', specChemProduct: 'Quartz Floor Hardener' },
                { name: 'SpecCrete Red Line', specChemProduct: 'SpecPatch 5' },
                { name: 'Speed Crete Red Line', specChemProduct: 'SpecPatch 15' },
                { name: 'Eucopatch, Thin Patch, EucoCrete, Eucopatch', specChemProduct: 'SpecPatch 30' },
                { name: 'Speed Crete Red Line', specChemProduct: 'SpecPatch Light' },
                { name: 'Tammspatch II, DuraTop Flowable Mortar', specChemProduct: 'Duo Patch' },
                { name: 'Tammscrete', specChemProduct: 'SpecSmooth' },
                { name: 'Verticoat Supreme, Verticoat, V-100', specChemProduct: 'RepCon V_O' },
                { name: 'Concrete Top Supreme', specChemProduct: 'RepCon H' },
                { name: 'Versaspeed LS', specChemProduct: 'RepCon 928' },
                { name: 'Versaspeed', specChemProduct: 'RepCon 928 FS' },
                { name: 'Speed Crete Blue Line', specChemProduct: 'RepCon UW Mortar' },
                { name: 'Speed Plug', specChemProduct: 'SpecPlug' },
                { name: 'Euco Rock', specChemProduct: 'SpecRock' },
                { name: 'Flop Top, Super Flo Top', specChemProduct: 'SpecFlow' },
                { name: 'Tammscrete', specChemProduct: 'Final Finish' },
                { name: 'Euco Recover', specChemProduct: 'OverCrete Coarse' },
                { name: 'Thin Top Supreme', specChemProduct: 'OverCrete Fine' }, 
                { name: 'FormShield WB', specChemProduct: 'SpecStrip WB' },
                { name: 'Diamond Clear, Rez Seal, Super RezSeal', specChemProduct: 'Cure & Seal 25' },
                { name: 'Super Aqua-Cure VOX, Super Diamond Clear VOX', specChemProduct: 'Cure & Seal WB 25' },
                { name: 'Kurez DR VOX, Kurez 100', specChemProduct: 'SpecRez' },
                { name: 'Kurez DR VOX, Kurez R 100', specChemProduct: 'Pave Cure Rez' },
                { name: 'Weather-Guard, ChemStop WB, Baracade WB 244', specChemProduct: 'SpecShield WB' },
                { name: 'Baracade Silane 100C', specChemProduct: 'SpecSilane 100' },
                { name: 'Flex-con, Akkro-7t', specChemProduct: 'Strong Bond' },
                { name: 'EucoWeld, Tammsweld', specChemProduct: 'SpecWeld' },
                { name: 'Eucopatch, Thin Patch', specChemProduct: 'SpecPatch 30' },
                { name: 'Eucoplate HD', specChemProduct: 'SpecPlate' },
                { name: 'Eucoplate Diamond Plate', specChemProduct: 'SpecPlate NOX' },
                { name: 'Super Euco Top', specChemProduct: 'SpecPlate NOX' },
                { name: 'Eucobar', specChemProduct: 'SpecFilm Concentrate' },
                { name: 'Duralprep AC', specChemProduct: 'SpecPrep SB' },
                { name: 'EucoWeld 2.0', specChemProduct: 'Stucco Bond' },
                { name: 'Flo-Top, Super Flo-Top', specChemProduct: 'SpecFlow' },
                { name: 'Thin-Top Supreme, Euco Recover', specChemProduct: 'RevCrete' },
                { name: 'Quikstitch', specChemProduct: 'Poly Fix' },
                { name: 'Dural #452 LV', specChemProduct: 'SpecPoxy 1000' },
                { name: 'Dural #452 MV', specChemProduct: 'SpecPoxy 2000' },
                { name: 'Dural #452 Gel', specChemProduct: 'SpecPoxy 3000' },
            ]
        },
        {
            name: 'Five Star',
            products: [
                { name: 'Five Star Grout', specChemProduct: 'SC Multi-purpose Grout' },
                { name: 'Five Star Fluid 100 Grout', specChemProduct: 'SC Precision Grout' },
                { name: 'Five Star Products Rapid Surface Repair R-60', specChemProduct: 'SpecPoxy Sealer' },
                { name: 'Five Star Strength Grout', specChemProduct: 'SC Precision Grout HS' },
                { name: 'Five Star High Strength UW Grout', specChemProduct: 'UW Grout' },
                { name: 'Five Star Structural Concrete: Underwater HP', specChemProduct: 'RepCon UW Mortar' },
                { name: 'Five Star Structural Concrete', specChemProduct: 'RepCon 928' },
                { name: 'Five Star Hwy Patch', specChemProduct: 'RepCon 928 FS' },
                { name: 'Five Star Structural VO', specChemProduct: 'RepCon V_O' },
                { name: 'Five Star DP Epoxy Grout', specChemProduct: 'SpecPoxy Grout' },

            ]
        },
        {
            name: 'Glaze N Seal',
            products: [
                { name: '(GS) Wet Lacquer 2000', specChemProduct: 'Crystal Shine' },
                { name: '(GS) Wet Look II Sealer', specChemProduct: 'Aqua Shine' },
                { name: '(GS) Original Wet Lacquer', specChemProduct: 'Deco Shine' },
                { name: '(GS) Multi-Purpose Sealer', specChemProduct: 'Cure & Seal WB' },
            ]
        },
        {
            name: 'Lambert',
            products: [
                { name: 'Lambert Form Release 88 VOC', specChemProduct: 'SpecStrip' },
                { name: 'Lambert Crystal Clear Seal WB', specChemProduct: 'Cure & Seal WB' },
                { name: 'Lambert Crystal Clear Seal 1315 WB', specChemProduct: 'Cure & Seal WB 25' },
                { name: 'Lambert Crystal Clear Seal SB', specChemProduct: 'Cure & Seal 25' },
                { name: 'Lambert Crystal Clear Seal 30 SB', specChemProduct: 'Cure & Seal 30' },
                { name: 'Lambert Form Release WB', specChemProduct: 'SpecStrip WB' },
                { name: 'Lambert Lambco® Plug', specChemProduct: 'SpecPlug' },
                { name: 'Lambert Exposite SB', specChemProduct: 'Surface Shine' },
                { name: 'Lambert Vibropruf 20', specChemProduct: 'SC Precision Grout' },
                { name: 'Lambert Epiweld® 9-N-7', specChemProduct: 'SpecPoxy Mortar' },
                { name: 'Lambert Epiweld® 580', specChemProduct: 'SpecPoxy 2000' },
                { name: 'Lambert Vibropruf #11, Vibropruf NS', specChemProduct: 'SC Multi-purpose Grout' },
                { name: 'Lambert Aqua Kure Clear', specChemProduct: 'SpecRez' },
                { name: 'Lambert Lambco Skin', specChemProduct: 'SpecFilm Concentrate' },
                { name: 'Lambert Glazecote 20', specChemProduct: 'Cure & Seal WB' },
                { name: 'Lambert Solidus®', specChemProduct: 'Spec-O-Lith' },
                { name: 'Lambert Crystal Clear Seal 1315 SB, UV Super Seal, Exposite SB', specChemProduct: 'Cure & Seal 25 UV' },
                { name: 'Lambert Acryl Bond', specChemProduct: 'Strong Bond' },
                { name: 'Lambert 932 Link', specChemProduct: 'Stucco Bond' },
                { name: 'Lambert Gardseal', specChemProduct: 'Cure Hard' },
                { name: 'Lambert Renew Coat Broom', specChemProduct: 'OverCrete Fine' },
                { name: 'Lambert Renew Coat Trowel', specChemProduct: 'OverCrete Coarse' },
                { name: 'Lambert Fast Patch 2', specChemProduct: 'Duo Patch' },
            ]
        },
        {
            name: 'Kaufman',
            products: [
                { name: 'Kaufman UW Grout', specChemProduct: 'UW Grout' },
                { name: 'Kaufman Sure Weld', specChemProduct: 'SpecWeld' },
                { name: 'Kaufman Sure Bond', specChemProduct: 'Strong Bond' },
                { name: 'Kaufman Surepoxy HMLV', specChemProduct: 'SpecPoxy 1000' },
                { name: 'Kaufman Surepoxy HM', specChemProduct: 'SpecPoxy 2000' },
                { name: 'Kaufman Surepoxy HM Gel', specChemProduct: 'SpecPoxy 3000' },
                { name: 'Kaufman Silicure', specChemProduct: 'Cure Hard' },
                { name: 'Kaufman Hi Cap', specChemProduct: 'SpecPatch Light' },
                { name: 'Kaufman K-Pro', specChemProduct: 'SpecPoxy Grout' },
                { name: 'Kaufman FormKote OTC', specChemProduct: 'SpecStrip' },
            ]
        },
        {
            name: 'L&M',
            products: [
                { name: 'L&M E-Z Strip', specChemProduct: 'SpecStrip' },
                { name: 'L&M Duracrete', specChemProduct: 'SpecPatch 15' },
                { name: 'L&M Dress & Seal 30', specChemProduct: 'Cure & Seal 30' },
                { name: 'L&M Dress & Seal WB', specChemProduct: 'Cure & Seal WB' },
                { name: 'L&M Dress & Seal WB 25, Lumiseal WB Plus', specChemProduct: 'Cure & Seal WB 25' },
                { name: 'L&M De Bond Gold', specChemProduct: 'Precast 80' },
                { name: 'L&M Dress & Seal 25', specChemProduct: 'Cure & Seal 25' },
                { name: 'L&M LumiSeal Plus', specChemProduct: 'Cure & Seal 25 UV' },
                { name: 'L&M Cure R', specChemProduct: 'Pave Cure Rez' },
                { name: 'L&M Cure R-2', specChemProduct: 'Pave Cure Rez White' },
                { name: 'L&M Cure W', specChemProduct: 'Pave Cure WW' },
                { name: 'L&M Chem Hard', specChemProduct: 'Cure Hard' },
                { name: 'L&M Cure', specChemProduct: 'Cure Hard' },
                { name: 'L&M Lion Hard', specChemProduct: 'LithSeal SC' },
                { name: 'L&M Seal Hard', specChemProduct: 'SpecHard' },
                { name: 'L&M E-Con', specChemProduct: 'SpecFilm Concentrate' },
                { name: 'L&M Citrex', specChemProduct: 'Orange Peel' },
                { name: 'L&M AquaPel Plus', specChemProduct: 'SpecShield WB Extra' },
                { name: 'L&M AquaPel', specChemProduct: 'SpecShield WB' },
                { name: 'L&M Everbond', specChemProduct: 'Strong Bond' },
                { name: 'L&M Everweld', specChemProduct: 'SpecWeld' },
                { name: 'L&M Epogrout 758', specChemProduct: 'SpecPoxy Grout' },
                { name: 'L&M Duragrout', specChemProduct: 'SC Multi-purpose Grout' },
                { name: 'L&M Crystex', specChemProduct: 'SC Precision Grout' },
                { name: 'L&M Epogrout 758', specChemProduct: 'SpecPoxy Grout' },
                { name: 'L&M Duraplug', specChemProduct: 'SpecPlug' },
                { name: 'L&M Duracrete', specChemProduct: 'SpecPatch 30' },
                { name: 'L&M Cure R, Cure W2', specChemProduct: 'SpecRez' },
                { name: 'L&M DeBond Gold', specChemProduct: 'SpecStrip Supreme' },
                { name: 'L&M Debond', specChemProduct: 'SpecStrip' },
            ]
        },
        {
            name: 'Mapei',
            products: [
                { name: 'Mapei Mapecrete™ Hard LI', specChemProduct: 'LithSeal SC' },
                { name: 'Mapei Planicrete AC', specChemProduct: 'Strong Bond' },
                { name: 'Mapei Plantiop X, XS', specChemProduct: 'RepCon V_O' },
                { name: 'Mapei Ultraplan Easy, Novoplan Easy, Self Leveling Plus', specChemProduct: 'SpecFlow' },
                { name: 'Mapei Mapecem Quikpatch', specChemProduct: 'OverCrete Coarse' },

            ]
        },
        {
            name: 'Metzger-McGuire',
            products: [
                { name: 'Metzger-McGuire Armor-Hard', specChemProduct: 'SpecPoxy Mortar' },
                { name: 'Metzger-McGuire Rapid Refloor', specChemProduct: 'Poly Fix' },
                { name: 'Metzger-McGuire Edge Pro 90', specChemProduct: 'Rapid Flex 90' },
                { name: 'Metzer-McGuire MM-80, MM-80P', specChemProduct: 'SpecPoxy CJ' },
                { name: 'Metzger-McGuire RS 88, HiTech HT-PE85 MI', specChemProduct: 'Rapid Flex CJ' },
            ]
        },
        {
            name: 'Nox-Crete',
            products: [
                { name: 'Form Coating 250', specChemProduct: 'SpecStrip Supreme' },
                { name: 'Plus', specChemProduct: 'Precast 80' },
                { name: 'Bio-Nox', specChemProduct: 'Bio Strip WB' },
                { name: 'Chembeton 250', specChemProduct: 'SpecStrip' },
                { name: 'RA-10', specChemProduct: 'Precast 80' },
                { name: 'Cure & Seal 1315 A', specChemProduct: 'Cure & Seal 30' },
                { name: 'Cure & Seal 250 E', specChemProduct: 'Cure & Seal WB 25' },
                { name: 'PCE', specChemProduct: 'Dry-Deck WB' },
                { name: 'Cure & Seal 120 E', specChemProduct: 'Cure & Seal WB' },
                { name: 'CityCure WW', specChemProduct: 'SpecCity White' },
                { name: 'DOT Road Cure WW', specChemProduct: 'Pave Cure WW' },
                { name: 'DuroPolish Plus', specChemProduct: 'SpecGuard' },
                { name: 'Duro-Nox HSC', specChemProduct: 'LithSeal Lite' },
                { name: 'DuroNox', specChemProduct: 'SpecHard' },
                { name: 'Silcoseal 2000F', specChemProduct: 'SpecTilt WB' },
                { name: 'Silcoseal 100', specChemProduct: 'SpecTilt 100' },
                { name: 'BioClean', specChemProduct: 'Orange Peel' },
                { name: 'Nox-Carb', specChemProduct: 'Surface Shine' },
                { name: 'Sparkl-Seal', specChemProduct: 'Deco Shine' },
                { name: 'Sparkl-Seal E', specChemProduct: 'Aqua Shine' },
                { name: 'Form Coating 250, Plus', specChemProduct: 'SpecStrip Supreme' },
                { name: 'Panel Patch', specChemProduct: 'Final Finish' },
                { name: 'Bro-Cure', specChemProduct: 'Cure Hard' },
            ]
        },
        {
            name: 'Prosoco',
            products: [
                { name: 'ConSolideck LS', specChemProduct: 'LithSeal SC' },
                { name: 'ConSolideck LS CS', specChemProduct: 'LithSeal Lite' },
                { name: 'ColorHard', specChemProduct: 'Quartz Floor Hardener' },
                { name: 'LS Guard', specChemProduct: 'SpecGuard' },
                { name: '600', specChemProduct: 'SpecBlast' },
                { name: 'SLX-100', specChemProduct: 'All Shield EX' },
            ]
        },
        {
            name: 'Rapid Set',
            products: [
                { name: 'Rapid Set Wunderfixx', specChemProduct: 'Final Finish' },
                { name: 'Rapid Set Skim coat', specChemProduct: 'RevCrete' },
                { name: 'Rapid Set TRU Self Leveling', specChemProduct: 'SpecLevel PCT' },
                { name: 'Rapid Set Cement All', specChemProduct: 'RepCon 928' },
                { name: 'Rapid Set Cement All', specChemProduct: 'Total Patch' },
                { name: 'Rapid Set DOT Repair Mix', specChemProduct: 'RepCon 928' },
                { name: 'Rapid Set V/O Repair Mix', specChemProduct: 'RepCon V_O' },
                { name: 'Rapid Set LevelFlor', specChemProduct: 'SpecFlow' },
                { name: 'Rapid Set Cement All', specChemProduct: 'SpecPatch 5' },
                { name: 'Rapid Set TRU PC', specChemProduct: 'SpecLevel PCT-AGG' },
            ]
        },
        {
            name: 'Sika',
            products: [
                { name: 'SikaGrout UW', specChemProduct: 'UW Grout' },
                { name: 'SikaGrout 428FS', specChemProduct: 'SC Precision Grout HS' },
                { name: 'Sika Film', specChemProduct: 'SpecFilm Concentrate' },
                { name: 'Rugasol S', specChemProduct: 'SpecEtch' },
                { name: 'Aggribrite VOC', specChemProduct: 'Surface Shine' },
                { name: 'Aggribrite VOC', specChemProduct: 'Surface Shine EX' },
                { name: 'Sika Latex Acrylic, Sika Latex R', specChemProduct: 'Strong Bond' },
                { name: 'Sikadur 51', specChemProduct: 'SpecPoxy 500' },
                { name: 'Sikadur Hi Mod 35', specChemProduct: 'SpecPoxy 1000' },
                { name: 'Sikadur Hi Mod 32', specChemProduct: 'SpecPoxy 2000' },
                { name: 'Sikadur Hi Mod 31', specChemProduct: 'SpecPoxy 3000' },
                { name: 'Sikadur Hi Mod 33', specChemProduct: 'SpecPoxy 3000 FS' },
                { name: 'Sikadur 51 SL', specChemProduct: 'SpecPoxy CJ' },
                { name: 'Sika Loadflex 524 EZ', specChemProduct: 'Rapid Flex CJ' },
                { name: 'Sikadur 42', specChemProduct: 'SpecPoxy Grout' },
                { name: 'SikaGrout 212', specChemProduct: 'SC Multi-purpose Grout' },
                { name: 'SikaGrout 328', specChemProduct: 'SC Precision Grout' },
                { name: 'Sika Repair 223', specChemProduct: 'SpecPatch 15' },
                { name: 'Sika Repair 222', specChemProduct: 'SpecPatch 30' },
                { name: 'Sika Top 123 Plus, Sika Quick VOH', specChemProduct: 'RepCon V_O' },
                { name: 'Sika Set Mortar', specChemProduct: 'RepCon H' },
                { name: 'Sika Smooth', specChemProduct: 'Final Finish' },
                { name: 'Sika Armatec 110', specChemProduct: 'SpecPrep SB' },
                { name: 'Sikadur 22', specChemProduct: 'SpecPoxy Binder' },
                { name: 'Sikafloor 261, Sikagard 62', specChemProduct: 'SpecPoxy Coating' },
                { name: 'Sikadur 43, Patch-Pak', specChemProduct: 'SpecPoxy Mortar' },
                { name: 'Sika Repair 222', specChemProduct: 'SpecPatch 30' },
                { name: 'Sika Top 111 and 122 Plus', specChemProduct: 'Duo Patch' },
                { name: 'Sikaset Plug', specChemProduct: 'SpecPlug' },
                { name: 'SikaLevel SkimCoat', specChemProduct: 'RevCrete' },
                { name: 'Sikaquick 1000', specChemProduct: 'RepCon 928' },
                { name: 'Sikaquick 2500', specChemProduct: 'RepCon 928 FS' },
            ]
        },
        {
            name: 'TK Products',
            products: [
                { name: 'DC WB Dissipating Cure 2519', specChemProduct: 'Pave Cure Rez' },
                { name: 'Epoxy Patch 211', specChemProduct: 'SpecPoxy 1000' },
                { name: 'EPO 2000 Bonding Agent', specChemProduct: 'SpecPoxy 2000' },
                { name: 'LS Hard N Guard Lithium Silicate Blend', specChemProduct: 'SpecGuard' },
                { name: 'Epoxy Crack Filler 9000', specChemProduct: 'SpecPoxy 3000 FS' },
                { name: 'Kure & Seal 1315', specChemProduct: 'Cure & Seal 25' },
                { name: 'Bridge Deck & Crack Sealer 2110', specChemProduct: 'SpecPoxy Sealer' },
                { name: 'Tri-Bond', specChemProduct: 'SpecWeld' },
                { name: 'Epoxy Bridge Deck Overlay 2109', specChemProduct: 'SpecPoxy Binder' },
                { name: 'Liquid Bonding Agent 225', specChemProduct: 'Strong Bond' },
                { name: '590-100', specChemProduct: 'SpecSilane 20WB' },
                { name: 'Tri-Film 2120', specChemProduct: 'SpecFilm Concentrate' },
                { name: 'Concrete Surface Retarder 6000', specChemProduct: 'SpecEtch' },
            ]
        },
        {
            name: 'Vexcon',
            products: [
                { name: 'Vexcon Certi-Vex Envio RC 1000', specChemProduct: 'SpecRez' },
                { name: 'Vexcon StarSeal Envio Set', specChemProduct: 'SpecEtch' },
                { name: 'Vexcon Certi-Vex Concrete Remover', specChemProduct: 'SpecBlast' },
                { name: 'Vexcon MoistureBloc Floor Cleaner & Strip', specChemProduct: 'Orange Peel' },
                { name: 'Vexcon Certi-Vex Penseal 244 100', specChemProduct: 'SpecSilane 100' },
                { name: 'Vexcon StarSeal PS Clear', specChemProduct: 'Spec-O-Lith' },
                { name: 'Vexcon Certi-Vex Epoxy Grout 100', specChemProduct: 'SpecPoxy Grout' },
                { name: 'Vexcon PowerCoat Epoxy HD', specChemProduct: 'SpecPoxy Coating' },
                { name: 'Vexcon Certi-Vex Grout UW', specChemProduct: 'UW Grout' },
                { name: 'Vexcon Certi-Vex All Patch', specChemProduct: 'RevCrete' },
                { name: 'Vexcon Certi-Vex Anchor Cement', specChemProduct: 'SpecRock' },
                { name: 'Vexcon Certi-Vex Rapid Patch', specChemProduct: 'RepCon 928' },
                { name: 'Vexcon Certi-Vex Patch UW', specChemProduct: 'RepCon UW Mortar' },
                { name: 'Vexcon Certi-Vex Patch', specChemProduct: 'Duo Patch' },
            ]
        },
        {
            name: 'W.R. Meadows',
            products: [
                { name: 'Duogard, Duogard Citrus, Duoguard NE', specChemProduct: 'SpecStrip' },
                { name: 'Duogard Plus', specChemProduct: 'SpecStrip Supreme' },
                { name: 'CS-309-25', specChemProduct: 'Cure & Seal 25' },
                { name: 'VOCOMP-20', specChemProduct: 'Cure & Seal WB' },
                { name: 'VOCOMP-25, VOCOMP-30', specChemProduct: 'Cure & Seal WB 25' },
                { name: 'CS-309-35, TIAH, Decra-Seal', specChemProduct: 'Cure & Seal 25 UV' },
                { name: '1100 - Clear', specChemProduct: 'SpecRez' },
                { name: '1600-White', specChemProduct: 'Pave Cure WW' },
                { name: '1100-Clear', specChemProduct: 'Pave Cure Rez' },
                { name: '1200-White', specChemProduct: 'Pave Cure Rez White' },
                { name: 'Med-Cure', specChemProduct: 'Cure Hard' },
                { name: 'Liqui-Hard', specChemProduct: 'SpecHard' },
                { name: 'Evapre-RTU', specChemProduct: 'SpecFilm RTU' },
                { name: 'Evapre', specChemProduct: 'SpecFilm Concentrate' },
                { name: 'Top Stop', specChemProduct: 'SpecEtch' },
                { name: 'TIAH', specChemProduct: 'Surface Shine' },
                { name: 'TIAH', specChemProduct: 'Surface Shine EX' },
                { name: 'Decra-seal', specChemProduct: 'Deco Shine' },
                { name: 'Expo-Gloss OTC', specChemProduct: 'Crystal Shine' },
                { name: 'Deck-O-Shield', specChemProduct: 'SpecSilane 40WB' },
                { name: 'Acryl-Lok', specChemProduct: 'Strong Bond' },
                { name: 'Everweld', specChemProduct: 'SpecWeld' },
                { name: 'Rezi-Weld LV', specChemProduct: 'SpecPoxy 1000' },
                { name: 'Rezi-Weld 1000', specChemProduct: 'SpecPoxy 2000' },
                { name: 'Rezi-weld Gel Paste', specChemProduct: 'SpecPoxy 3000' },
                { name: 'Rezi-Weld Gel Paste', specChemProduct: 'SpecPoxy 3000 FS' },
                { name: 'Rezi-Weld Flex', specChemProduct: 'SpecPoxy CJ' },
                { name: 'Rezi-Weld 3/2', specChemProduct: 'SpecPoxy Grout' },
                { name: 'SG-86, CG-86', specChemProduct: 'SC Multi-purpose Grout' },
                { name: '588', specChemProduct: 'SC Precision Grout' },
                { name: 'Meadow-Patch 20, T-1', specChemProduct: 'SpecPatch 30' },
                { name: 'Futura 15', specChemProduct: 'RepCon H' },
                { name: 'Duogard II', specChemProduct: 'SpecStrip WB' },
                { name: 'CS-309-30, Decra-Seal', specChemProduct: 'Cure & Seal 30' },
                { name: 'Liqui-Hard Ultra', specChemProduct: 'LithSeal Lite' },
                { name: 'Meadow-Patch T1', specChemProduct: 'SpecPatch Light' },
                { name: 'Meadow-Patch T2', specChemProduct: 'Duo Patch' },
                { name: 'Futura 45', specChemProduct: 'RepCon 928' },
                { name: 'Meadow-Plug', specChemProduct: 'SpecPlug' },
                { name: 'Speed-E-Roc', specChemProduct: 'SpecRock' },
            ]
        },
        {
            name: 'Miscellaneous',
            products: [
                { name: 'Ameristar QuikRok', specChemProduct: 'Por-Rok' },
                { name: 'Hartline Rocktite', specChemProduct: 'Por-Rok' },
                { name: 'Cresset 727, Cresset Super Strip 60', specChemProduct: 'SpecStrip' },
                { name: 'Form Release Gold', specChemProduct: 'SpecStrip Plus' },
                { name: 'Crete-Lease 880 VOC', specChemProduct: 'SpecStrip Supreme' },
                { name: 'Western Blue Ribbon', specChemProduct: 'SpecStrip Ultra Blue' },
                { name: 'Crete-Lease 880', specChemProduct: 'Precast 80' },
                { name: 'Crete-Lease BioTru', specChemProduct: 'Bio Strip WB' },
                { name: '(RP) White Water Wax', specChemProduct: 'Pave Cure WW' },
                { name: '(RP) Clear Water Resin', specChemProduct: 'Pave Cure Rez' },
                { name: '(RP) White Water Resin', specChemProduct: 'Pave Cure Rez White' },
                { name: 'Ashford Formula', specChemProduct: 'SpecHard' },
                { name: 'Silcoseal Select', specChemProduct: 'SpecTilt WB' },
                { name: 'Silcoseal Classic', specChemProduct: 'SpecTilt 100' },
                { name: '(S) CureSeal - S Matte', specChemProduct: 'Deco Shine Matte' },
                { name: '(S) CureSeal - S', specChemProduct: 'Deco Shine' },
                { name: '(S) CureSeal - W', specChemProduct: 'Aqua Shine' },
                { name: '(GS) Natural Look', specChemProduct: 'SpecShield WB' },
                { name: 'Larsen\'s Weld Crete', specChemProduct: 'SpecWeld' },
                { name: 'Concressive 1375', specChemProduct: 'SpecPoxy 1000' },
                { name: 'Concressive LPL', specChemProduct: 'SpecPoxy 2000' },
                { name: 'Concressive 1444', specChemProduct: 'SpecPoxy 3000' },
                { name: 'VersaFlex SL 85, 88, Adhesives Technology Crackbond JF 311', specChemProduct: 'Rapid Flex CJ' },
                { name: 'CTS MP Grout', specChemProduct: 'SC Multi-purpose Grout' },
                { name: 'Quikcrete Precision Grout', specChemProduct: 'SC Multi-purpose Grout' },
                { name: 'Quikcrete DOT Anchoring Epoxy', specChemProduct: 'SpecPoxy 3000' },
                { name: 'High Performance Grout', specChemProduct: 'SC Precision Grout' },
                { name: 'SureGrip High Performance Grout', specChemProduct: 'SC Precision Grout' },
                { name: 'Quartzplate FF', specChemProduct: 'Quartz Floor Hardener' },
                { name: 'Lyons Pave Crete', specChemProduct: 'SpecPatch 30' },
                { name: 'EMACO 566', specChemProduct: 'RepCon H' },
                { name: 'Lyons Cast Patch', specChemProduct: 'SpecPatch 15' },
                { name: 'CTS Mortar Mix', specChemProduct: 'RepCon V_O' },
                { name: 'Lyons PatchCrete', specChemProduct: 'Duo Patch' },
                { name: 'Anti-Hydro Curing Compound #2 WP WB', specChemProduct: 'SpecRez' },
                { name: 'Certi-Vex Gloss Sealer FT', specChemProduct: 'Cure & Seal 30' },
                { name: 'V-Seal Industra-Gloss 350', specChemProduct: 'Cure & Seal WB 25' },
                { name: 'Silocks Plus Silpro', specChemProduct: 'SpecShield WB' },
                { name: 'Pecora KlereSeal® 9100S', specChemProduct: 'SpecSilane 20WB' },
                { name: 'Anti-Hydro Airdox 20', specChemProduct: 'SpecSilane 40WB' },
                { name: 'US Concrete Products HP Bondit', specChemProduct: 'Strong Bond' },
                { name: 'Anti-Hydro Silica Epoxy Topping #140', specChemProduct: 'SpecPoxy Mortar' },
                { name: 'Poly-Carb, Inc. Mark-151.6, 154 Safe-T-Grid, 163', specChemProduct: 'SpecPoxy Binder' },
                { name: 'Tennant Eco-CEP', specChemProduct: 'SpecPoxy Coating' },
                { name: 'Tennant Eco-EJF System', specChemProduct: 'SpecPoxy CJ' },
                { name: 'DeNeef Denepox I-40', specChemProduct: 'SpecPoxy Sealer' },
                { name: 'Quikrete DOT Anchoring Epoxy', specChemProduct: 'SpecPoxy 3000' },
                { name: 'DeNeef Denepox Rapid Gel', specChemProduct: 'SpecPoxy 3000 FS' },
                { name: 'Anti-Hydro Axpandcrete', specChemProduct: 'SC Metallic Grout' },
                { name: 'US Concrete Products HP Underwater Grout', specChemProduct: 'UW Grout' },
                { name: 'US Spec Dense Top', specChemProduct: 'Quartz Floor Hardener' },
                { name: 'Anti-Hydro S-Q Hardener', specChemProduct: 'Quartz Floor Hardener' },
                { name: 'US Spec 3-2-1', specChemProduct: 'SpecSmooth' },
                { name: 'US Concrete Products US Thin Patch V/O 103', specChemProduct: 'SpecPatch 5' },
                { name: 'US Spec H2', specChemProduct: 'RepCon H' },
                { name: 'Quikrete Anchoring Cement- Exterior Use', specChemProduct: 'SpecRock' },
                { name: 'US Spec Quickset 20', specChemProduct: 'SpecPatch 5' },
                { name: 'Conproco Conpro Set, Quick Shot', specChemProduct: 'RepCon H' },
                { name: 'Lyons Plug-Crete', specChemProduct: 'SpecPlug' },
                { name: 'Lyons Flow-Rock', specChemProduct: 'SpecRock' },
                { name: 'Pro Spec Feather Edge', specChemProduct: 'Final Finish' },
                { name: 'Lanco CB-900', specChemProduct: 'Stucco Bond' },
                { name: 'Divergent Pentrasil NL', specChemProduct: 'LithSeal SC' },
                { name: 'Bostik Level 1', specChemProduct: 'Sleek 1000 SLU' },
                { name: 'Laticrete Drytek Levelex', specChemProduct: 'SpecFlow' },
                { name: 'SilPro Silflo 220', specChemProduct: 'SpecFlow' },
                { name: 'SilPro TDQ', specChemProduct: 'SpecFlow' },
                { name: 'HB Fuller Blendcrete', specChemProduct: 'SpecPatch 15' },
                { name: 'HB Fuller Blendcrete', specChemProduct: 'SpecPatch Light' },
                { name: 'Roadware 10 Minute Mender', specChemProduct: 'Poly Fix' },
                { name: 'Silcoseal LVOC', specChemProduct: 'SpecTilt EX' },
                { name: 'Ameripolish SR-2', specChemProduct: 'All Shield EX' },
                { name: 'Waterban 90', specChemProduct: 'SpecShield WB' },
                { name: 'Protectosil Chemtrete BSM 400', specChemProduct: 'SpecSilane 100' },
                { name: 'Protectosil BHN', specChemProduct: 'SpecSilane 100' },
            ]
        },
    ];

    @Watch('$route', { immediate: true, deep: true })
    private onUrlChange(newVal: Route) {
        if (this.$route.path.includes('productname')) {
            if (this.$route.params.reload) {
                this.$router.push(this.$route.path + '/productname/' + this.$route.params.name + '/false');
            }

            this.GetSpecChemProduct(this.$route.params.name);
        } else if (this.$route.path.includes('crossoverchart')) {
            this.ResetPage();
        }
    }

    private showMenu(showMenu) {
        this.isShow = showMenu;
    }

    private ResetPage() {
        this.productSelected = false;
    }

    private GetCompetitorProducts(competitorName) {
        this.productsFound = false;
        if (this.competitorNameMatch === competitorName) {
            this.competitorNameMatch = '';
            return false;
        }

        const CompetitorProductItems = this.CompetitorProducts.find((e) => e.name === competitorName);
        this.competitorNameMatch = competitorName;
        if (CompetitorProductItems === undefined) {
            throw new Error('One of the params must be provided.');
        } else if (CompetitorProductItems !== undefined) {
            this.productsFound = true;
            this.Scroll(competitorName);
            this.CompetitorProductList = CompetitorProductItems.products.slice().sort((a, b) => {
                return (a.name > b.name) ? 1 : -1;
            });
        }
    }

    private GetSpecChemProductURL(specChemProductName: any) {
        this.$router.push(this.$route.path + '/productname/' + specChemProductName);
    }

    private GetSpecChemProduct(specChemProductName: any) {
        const SpecChemProductName = this.SpecChemProductIDs.find((e) => e.specChemProduct === specChemProductName);
        if (SpecChemProductName === undefined) {
            throw new Error('One of the params must be provided.');
        } else if (SpecChemProductName !== undefined) {
            this.productIDFound = true;
            const SpecChemProductID = SpecChemProductName.ID;
            this.GetProduct(SpecChemProductID, specChemProductName);
        }
    }

    private GetProduct(productID: any, productName: any) {
        console.log('GetProduct called with productID:', productID, 'and productName:', productName);
        this.isLoading = true;
        window.scrollTo(0, 0);
        this.productSelected = true;
        this.productName = productName.replace(/_/g, "/");

        axios.get(this.getProductURL + productID)
            .then((response: any) => {
                this.isLoading = false;
                this.productFound = true;
                this.productData = response.data;

                if (this.productData && this.productData.excerpt && this.productData.excerpt.rendered) {
                    this.productName = this.productData.title.rendered;
                    this.productDescription = this.productData.excerpt.rendered;

                    const acf = this.productData.acf;
                    this.productData.sheets = [
                            { name: 'Tech Data Sheet', link: acf.tech_data_sheet },
                            { name: 'Safety Data Sheet', link: acf.safety_data_sheet },
                            { name: 'Sell Sheet', link: acf.sell_sheet },
                            { name: 'Spanish TDS', link: acf.tds_esp },
                        ].filter(sheet => sheet.link)
                        .map((sheet, index) => ({ ...sheet, circlePosition: index + 1 }));
                } else {
                    console.error('productData is missing');
                }

                if (this.productData) {
                    this.productsFound = true;
                }
            })
            .catch((error: any) => {
                console.error('Error in axios request:', error);
                // handle error
                if (error.response) {
                    this.errorString = 'Data: ' + error.response.data;
                    this.errorString += '<br> Status: ' + error.response.status + '<br> Header: ' + error.response.headers + '<br>';
                } else if (error.request) {
                    this.errorString = 'Request Error occurred: ' + error.request + '<br>';
                } else {
                    this.errorString = 'Else Error occurred: ' + error.message + '<br>';
                }
            })
            .then(() => {
                // always executed
                this.productSelected = true;
                this.finalString = 'Done processing!';
            });
    }

    private Scroll(elementid: any) {
        console.log('Scroll called with elementid:', elementid);
        const element = document.getElementById(elementid);
        if (element) {
            const bodyRect = document.body.getBoundingClientRect();
            const elemRect = element.getBoundingClientRect();
            const offset = elemRect.top - bodyRect.top;
            console.log('Calculated offset:', offset);
            if (offset > 500) {
                const scrollToLocation = offset;
                setTimeout(() => window.scrollTo(0, scrollToLocation), 1);
            }
        } else {
            console.error('Element not found for ID:', elementid);
        }
    }
