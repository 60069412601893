const LOCAL_VERSION = "1.0.11"; // This should match your current app version
async function checkForUpdates(): Promise<void> {
    try {
        const response = await fetch(`${process.env.BASE_URL}version.json`);
        const data = await response.json();
        const serverVersion = data.version;

        const localStoredVersion = localStorage.getItem('app_version');

        if (localStoredVersion !== serverVersion) {
            localStorage.setItem('app_version', serverVersion);
            if (LOCAL_VERSION !== serverVersion) {
                if (confirm('A new version of the app is available. Refresh to update?')) {
                    window.location.reload();
                }
            }
        }
    } catch (error) {
        console.error('Error checking for updates:', error);
    }
}

export { checkForUpdates };
