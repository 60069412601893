
import axios from 'axios';
import VueAxios from 'vue-axios';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import Footer from '@/components/includes/Footer.vue';
import Menu from '@/components/includes/Menu/Menu.vue';
import MenuOptions from '@/components/includes/Menu/MenuOptions.vue';

@Component({
  components: {
    Footer,
    Menu,
    MenuOptions,
  },
})
export default class Calculator extends Vue {
  @Prop() private msg!: string;
  private returnData: string = '';
  private showOptions: boolean = true;
  private showEpoxy: boolean = false;
  private showCementous: boolean = false;
  private showCementousResults: boolean = false;
  private showEpoxyResults: boolean = false;
  private barDiameter: number = 0;
  private holeDiameter: number = 0;
  private numberOfHoles: number = 0;
  private depth: number = 0;
  private length: number = 0;
  private width: number = 0;
  private yieldPerBag: number = 0;
  private qty: number = 0;
  private yieldPerBagOther: number = 0;
  private totalCubicFeetDisplay: number = 0;
  private totalConcreteBagsDisplay: number = 0;
  private epoxyGallonsDisplay: string = '';
  private specPoxyTubesNeededDisplay: string = '';
  private yieldOther: boolean = false;
  private isShow: boolean = false;
  private calculatorType: string = '';
  private cementitiousErrors: string[] = [];
  private epoxyErrors: string[] = [];

  @Watch('$route', { immediate: true, deep: true })
   private onUrlChange(newVal: Route) {
      if (this.$route.path.includes('calculateepoxy')) {
        this.doEpoxyCalculation();
      } else if (this.$route.path.includes('epoxy')) {
        this.showEpoxyInfo();
      } else if (this.$route.path.includes('calculatecementitous')) {
        this.doCementitousCalculation();
      } else if (this.$route.path.includes('cementitous')) {
        this.showCementousInfo();
      } else if (this.$route.path.includes('calculator')) {
        this.calculatorType = 'Calculators';
        this.openOptions();
      }
    }

  private showMenu(showMenu) {
    this.isShow = showMenu;
  }

  private onChange(event: any) {
    if (event.target.value === 'other') {
      this.yieldOther = true;
    }
  }

  private mounted() {
    this.showOptions = true;
    this.calculatorType = 'Calculators';
  }

  private openOptions() {
    this.showOptions = true;
    this.showEpoxy = false;
    this.showCementous = false;
  }

  private openEpoxy() {
    if (!this.$route.path.includes('epoxy')) {
       this.$router.push(this.$route.path + '/epoxy');
    } else {
      this.showEpoxyInfo();
    }
  }

  private showEpoxyInfo() {
    this.showOptions = false;
    this.showEpoxy = true;
    this.showCementous = false;
    this.showEpoxyResults = false;
    this.barDiameter = 0;
    this.holeDiameter = 0;
    this.numberOfHoles = 0;
    this.depth = 0;
    this.calculatorType = 'Epoxy Calculator';
  }

  private openCementitous() {
    if (!this.$route.path.includes('cementitous')) {
        this.$router.push(this.$route.path + '/cementitous');
    } else {
      this.showCementousInfo();
    }
  }

  private showCementousInfo() {
    this.showOptions = false;
    this.showEpoxy = false;
    this.showCementous = true;
    this.showCementousResults = false;
    this.length = 0;
    this.width = 0;
    this.depth = 0;
    this.qty = 0;
    this.yieldPerBagOther = 0;
    this.yieldPerBag = 0;
    this.calculatorType = 'Cementitious Calculator';
  }

  private calculateEpoxy(event) {
    this.checkEpoxyForm();

    if (this.epoxyErrors.length > 0) {
      event.preventDefault();
      return false;
    }

    this.$router.push(this.$route.path + '/calculateepoxy');
  }

  private doEpoxyCalculation() {
    this.showEpoxyResults = true;
    this.showEpoxy = false;

    const volumeOfHole = .785 * this.holeDiameter * this.holeDiameter * this.depth;
    const volumeOfBar = .785 * this.barDiameter * this.barDiameter * this.depth;
    const volumeOfAnnulus = volumeOfHole - volumeOfBar;
    const gallonsOfEpoxyPerHole = volumeOfAnnulus / 231;
    const numberOfHolesPerGallon = 1 /  gallonsOfEpoxyPerHole;
    const epoxyGallons = +Number(this.numberOfHoles *  gallonsOfEpoxyPerHole).toFixed(1);
    const specPoxyTubesNeededDisplay = Number(epoxyGallons * 6).toFixed(0);

    this.epoxyGallonsDisplay = epoxyGallons.toString();
    this.specPoxyTubesNeededDisplay = Math.floor(+specPoxyTubesNeededDisplay).toString();
  }

  private calculateCementitous(event) {
    this.checkCementitiousForm();

    if (this.cementitiousErrors.length > 0) {
      event.preventDefault();
      return false;
    }
    this.$router.push(this.$route.path + '/calculatecementitous');
  }

  private checkCementitiousForm() {
    this.cementitiousErrors = [];
    this.cementitiousErrors.length = 0;

    if (this.length && this.width && this.depth && this.qty && (Number(this.yieldPerBag)
        || Number(this.yieldPerBag) !== 0)) {
      return true;
    }

    if (this.yieldOther && Number(this.yieldPerBagOther) !== 0) {
      return true;
    }

    if (!this.length) {
      this.cementitiousErrors.push('Length required.');
    }
    if (!this.width) {
      this.cementitiousErrors.push('Width required.');
    }
    if (!this.depth) {
      this.cementitiousErrors.push('Depth required.');
    }
    if (!this.qty) {
      this.cementitiousErrors.push('Quantity required.');
    }
    if (!this.yieldOther) {
      if (Number(this.yieldPerBag) === 0) {
        this.cementitiousErrors.push('Yield required.');
      }
    } else {
      if (Number(this.yieldPerBagOther) === 0) {
        this.cementitiousErrors.push('Yield required.');
      }
    }
  }

  private checkEpoxyForm() {
    this.epoxyErrors = [];
    this.epoxyErrors.length = 0;

    if (this.barDiameter && this.holeDiameter && this.depth && this.numberOfHoles) {
      return true;
    }

    if (!this.barDiameter) {
      this.epoxyErrors.push('Bar Diameter required.');
    }
    if (!this.holeDiameter) {
      this.epoxyErrors.push('Hole Diameter required.');
    }
    if (!this.depth) {
      this.epoxyErrors.push('Depth required.');
    }
    if (!this.numberOfHoles) {
      this.epoxyErrors.push('Number of Holes required.');
    }
  }

  private doCementitousCalculation() {
    this.showCementousResults = true;
    this.showCementous = false;
    let selectedYieldPerBag = 0;

    if (!this.yieldOther) {
      selectedYieldPerBag = this.yieldPerBag;
    } else {
      selectedYieldPerBag = this.yieldPerBagOther;
    }

    const cubicFeet = Number(((this.length * 12) * (this.width * 12) * this.depth) / 1728).toFixed(2);
    const cubicYards = Number(+cubicFeet * this.qty).toFixed(2);
    const bagsNeeded = Number(+cubicYards / selectedYieldPerBag).toFixed(2);

    this.totalCubicFeetDisplay = +cubicYards;
    this.totalConcreteBagsDisplay = +bagsNeeded;
  }
}
