<template>
    <div class="dotapprovalsContent">
        <div class="dotapprovals">
            <div class="headerMain">
                <h1>DOT Approvals</h1>
            </div>
            <Menu @showMenu="showMenu" v-if="isShow" /></Menu>
    <div class="contentMain">
      <div v-if="showProductList">
        <label for="state-select">Select a State:</label>
        <select id="state-select" v-model="selectedState" @change="fetchProducts">
            <option value="" disabled>Select a state</option>
            <option v-for="state in states" :key="state.id" :value="state.id">{{ state.title.rendered }}</option>
        </select>
      </div>
        <div class="rowText white bold font22 upper padR12 padL30 clickable" v-if="showProductList">
            <div v-for="product in products" :key="product.ID" @click="GetProduct(product.ID)" :data-product-id="product.ID">
                {{ product.name }}
            </div>
        </div>
        <div v-if="!showProductList">
            <div class="contentInnerPLProducts backgroundYellow">
                <div class="productSheets">
                    <div class="specChemProductOuterCircle">
                        <div class="specChemProductCircle">
                            <div class="specChemProductCircleText" v-html="productName" />
                        </div>
                    </div>
                    <div v-for="product in productData" v-bind:key="product.id">
                        <div v-for="(sheet, index) in product.sheets" :key="index" v-bind:class="{ 'productSheetOuterCircle0': sheet.circlePosition == 0, 'productSheetOuterCircle1': sheet.circlePosition == 1, 'productSheetOuterCircle2': sheet.circlePosition == 2, 'productSheetOuterCircle3': sheet.circlePosition == 3, 'productSheetOuterCircle4': sheet.circlePosition == 4, 'productSheetOuterCircle5': sheet.circlePosition == 5, 'productSheetOuterCircle6': sheet.circlePosition == 6 }">
                            <div class="productSheetCircle">
                                <div class="productSheetCircleText">
                                    <a target="_blank" v-bind:href="sheet.link" v-if="sheet.link">
                                        <span>{{ sheet.name }}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="productDescription">
                        <div class="productDescriptionInner">
                            <p v-html="productDescription" />
                            </p>
                        </div>
                    </div>
                </div>
                <div class="productSheetsFooter"><img src="/img/white-background.webp" width="400px" /></div>
            </div>
        </div>
    </div>
    <Footer @showMenu="showMenu" /></Footer>
</div>
</div>
</div>
</template>
<script>
import axios from 'axios';
import VueAxios from 'vue-axios';
import Footer from '@/components/includes/Footer.vue';
import Menu from '@/components/includes/Menu/Menu.vue';
import MenuOptions from '@/components/includes/Menu/MenuOptions.vue';
import Product from '@/models/productModel.vue';

export default {
  components: {
    Footer,
    Menu,
    MenuOptions,
  },
    data() {
        return {
            states: [],
            selectedState: "",
            products: [],
            productData: [],
            showProductList: true,
            SpecChemProductIDs: [
                { specChemProduct: 'All Shield EX', ID: '7584' },
                { specChemProduct: 'All Shield WB', ID: '7839' },
                { specChemProduct: 'Aqua Shine', ID: '7522' },
                { specChemProduct: 'Berry Clean', ID: '7498' },
                { specChemProduct: 'Bio Strip WB', ID: '7920' },
                { specChemProduct: 'Bio Strip WB Concentrate', ID: '7917' },
                { specChemProduct: 'C-Set', ID: '7495' },
                { specChemProduct: 'Clean Lift 20/20', ID: '7921' },
                { specChemProduct: 'Concrete Glue', ID: '7544' },
                { specChemProduct: 'Crystal Rez', ID: '7504' },
                { specChemProduct: 'Crystal Shine', ID: '7523' },
                { specChemProduct: 'Crystal Shine Matte', ID: '7524' },
                { specChemProduct: 'Cure & Seal 25', ID: '7505' },
                { specChemProduct: 'Cure & Seal 25 EX', ID: '7506' },
                { specChemProduct: 'Cure & Seal 25 UV', ID: '7507' },
                { specChemProduct: 'Cure & Seal 25 UV EX', ID: '7508' },
                { specChemProduct: 'Cure & Seal 30', ID: '7509' },
                { specChemProduct: 'Cure & Seal 30 EX', ID: '7510' },
                { specChemProduct: 'Cure & Seal EX', ID: '7511' },
                { specChemProduct: 'Cure & Seal WB', ID: '7512' },
                { specChemProduct: 'Cure & Seal WB 25', ID: '7513' },
                { specChemProduct: 'Cure & Seal WB 30', ID: '7514' },
                { specChemProduct: 'Cure Hard', ID: '7515' },
                { specChemProduct: 'Cure Shield', ID: '7516' },
                { specChemProduct: 'Cure Shield EX', ID: '7517' },
                { specChemProduct: 'Cure Shield WB', ID: '9179' },
                { specChemProduct: 'Deco Liquid Release', ID: '7922' },
                { specChemProduct: 'Deco Shine', ID: '7525' },
                { specChemProduct: 'Deco Shine Brown EX', ID: '7923' },
                { specChemProduct: 'Deco Shine Matte', ID: '7526' },
                { specChemProduct: 'Dry Deck WB', ID: '8176' },
                { specChemProduct: 'Duo Patch', ID: '7562' },
                { specChemProduct: 'E-Cure', ID: '7518' },
                { specChemProduct: 'EZ Clean & Release', ID: '7924' },
                { specChemProduct: 'Final Finish', ID: '7563' },
                { specChemProduct: 'Form Seasoning WB', ID: '7925' },
                { specChemProduct: 'IntraCon', ID: '7564' },
                { specChemProduct: 'IntraFilm', ID: '9180' },
                { specChemProduct: 'K-Set', ID: '7496' },
                { specChemProduct: 'LithSeal Lite', ID: '7549' },
                { specChemProduct: 'LithSeal SC', ID: '7550' },
                { specChemProduct: 'MVM Prime 5000', ID: '9181' },
                { specChemProduct: 'Max-A-Patch ACP', ID: '7579' },
                { specChemProduct: 'Max-A-Seal Asphalt Paint', ID: '7580' },
                { specChemProduct: 'Max-A-Seal DP', ID: '7581' },
                { specChemProduct: 'Max-A-Seal WP', ID: '7582' },
                { specChemProduct: 'Max-A-Tack TTC', ID: '7583' },
                { specChemProduct: 'Orange Peel', ID: '7499' },
                { specChemProduct: 'OverCrete', ID: '7565' },
                { specChemProduct: 'Pave Cure AMS', ID: '7554' },
                { specChemProduct: 'Pave Cure CW', ID: '7555' },
                { specChemProduct: 'Pave Cure CW W/Dye TX Type I', ID: '7926' },
                { specChemProduct: 'Pave Cure Rez', ID: '7927' },
                { specChemProduct: 'Pave Cure Rez White', ID: '7556' },
                { specChemProduct: 'Pave Cure Rez White - TX Type II', ID: '7557' },
                { specChemProduct: 'Pave Cure WW', ID: '7558' },
                { specChemProduct: 'Pave Cure WW-300', ID: '7560' },
                { specChemProduct: 'Poly Fix', ID: '7928' },
                { specChemProduct: 'PolyCure', ID: '7519' },
                { specChemProduct: 'Por-Rok', ID: '7536' },
                { specChemProduct: 'Por-Rok Aqua Plug', ID: '8177' },
                { specChemProduct: 'Por-Rok Concrete Patch', ID: '8178' },
                { specChemProduct: 'Precast 80', ID: '7929' },
                { specChemProduct: 'Pro Plug 45', ID: '7566' },
                { specChemProduct: 'Quartz Floor Hardener', ID: '7535' },
                { specChemProduct: 'Rapid Flex 90', ID: '7930' },
                { specChemProduct: 'Rapid Flex CJ', ID: '7931' },
                { specChemProduct: 'Rapid Flex Colorant System', ID: '9182' },
                { specChemProduct: 'RepCon 928', ID: '7567' },
                { specChemProduct: 'RepCon 928 FS', ID: '7568' },
                { specChemProduct: 'RepCon H', ID: '7569' },
                { specChemProduct: 'RepCon H-350', ID: '7570' },
                { specChemProduct: 'RepCon UW Mortar', ID: '7571' },
                { specChemProduct: 'RepCon V/O', ID: '7572' },
                { specChemProduct: 'RevCrete', ID: '7573' },
                { specChemProduct: 'SC Concrete Finish', ID: '7574' },
                { specChemProduct: 'SC Cure 500', ID: '7520' },
                { specChemProduct: 'SC Foundation Coating', ID: '7575' },
                { specChemProduct: 'SC Metallic Grout', ID: '7537' },
                { specChemProduct: 'SC Multi-Purpose Grout', ID: '7538' },
                { specChemProduct: 'SC Multi-Purpose Sealer', ID: '7932' },
                { specChemProduct: 'SC Precision Grout', ID: '7539' },
                { specChemProduct: 'SC Precision Grout HS', ID: '7540' },
                { specChemProduct: 'Sealer Renew', ID: '7500' },
                { specChemProduct: 'Sleek 1000 SLU', ID: '7933' },
                { specChemProduct: 'Solvent 100', ID: '7501' },
                { specChemProduct: 'Spec CJ Guard', ID: '7934' },
                { specChemProduct: 'Spec-O-Lith', ID: '7551' },
                { specChemProduct: 'SpecBlast', ID: '7502' },
                { specChemProduct: 'SpecCity White', ID: '7561' },
                { specChemProduct: 'SpecEtch', ID: '7935' },
                { specChemProduct: 'SpecEtch Deep', ID: '7936' },
                { specChemProduct: 'SpecFilm Concentrate', ID: '7937' },
                { specChemProduct: 'SpecFilm RTU', ID: '7938' },
                { specChemProduct: 'SpecFlex', ID: '7497' },
                { specChemProduct: 'SpecFlow', ID: '7829' },
                { specChemProduct: 'SpecGuard', ID: '7552' },
                { specChemProduct: 'SpecHard - Intraseal', ID: '7553' },
                { specChemProduct: 'SpecLevel PCT', ID: '7939' },
                { specChemProduct: 'SpecLevel PCT-AGG', ID: '7576' },
                { specChemProduct: 'SpecPatch 15', ID: '7942' },
                { specChemProduct: 'SpecPatch 30', ID: '7943' },
                { specChemProduct: 'SpecPatch 5', ID: '7941' },
                { specChemProduct: 'SpecPatch Light', ID: '7940' },
                { specChemProduct: 'SpecPlate Floor Topping', ID: '7944' },
                { specChemProduct: 'SpecPlate Hardener', ID: '7945' },
                { specChemProduct: 'SpecPlate NOX', ID: '7946' },
                { specChemProduct: 'SpecPlug', ID: '7947' },
                { specChemProduct: 'SpecPoxy 1000', ID: '7958' },
                { specChemProduct: 'SpecPoxy 2000', ID: '7955' },
                { specChemProduct: 'SpecPoxy 2000 LPL', ID: '7954' },
                { specChemProduct: 'SpecPoxy 3000', ID: '7957' },
                { specChemProduct: 'SpecPoxy 3000 FS', ID: '7956' },
                { specChemProduct: 'SpecPoxy 500', ID: '7953' },
                { specChemProduct: 'SpecPoxy Binder', ID: '7948' },
                { specChemProduct: 'SpecPoxy CJ', ID: '7949' },
                { specChemProduct: 'SpecPoxy Coating', ID: '7950' },
                { specChemProduct: 'SpecPoxy Grout', ID: '7977' },
                { specChemProduct: 'SpecPoxy Grout HS', ID: '7578' },
                { specChemProduct: 'SpecPoxy Mortar', ID: '7951' },
                { specChemProduct: 'SpecPoxy Sealer', ID: '7952' },
                { specChemProduct: 'SpecPrep SB', ID: '7962' },
                { specChemProduct: 'SpecPrime', ID: '7545' },
                { specChemProduct: 'SpecRez', ID: '7521' },
                { specChemProduct: 'SpecRock', ID: '7541' },
                { specChemProduct: 'SpecShield SB', ID: '7959' },
                { specChemProduct: 'SpecShield WB', ID: '7960' },
                { specChemProduct: 'SpecShield WB Extra', ID: '7961' },
                { specChemProduct: 'SpecShot Plus', ID: '7963' },
                { specChemProduct: 'SpecSilane 100', ID: '7585' },
                { specChemProduct: 'SpecSilane 20 WB', ID: '7964' },
                { specChemProduct: 'SpecSilane 40', ID: '8179' },
                { specChemProduct: 'SpecSilane 40 WB', ID: '7965' },
                { specChemProduct: 'SpecSmooth', ID: '7966' },
                { specChemProduct: 'SpecStrip', ID: '7967' },
                { specChemProduct: 'SpecStrip Plus', ID: '7968' },
                { specChemProduct: 'SpecStrip Supreme', ID: '7969' },
                { specChemProduct: 'SpecStrip Ultra Blue', ID: '7970' },
                { specChemProduct: 'SpecStrip VOC 100', ID: '7971' },
                { specChemProduct: 'SpecStrip WB', ID: '7972' },
                { specChemProduct: 'SpecTilt 100', ID: '7975' },
                { specChemProduct: 'SpecTilt EX', ID: '7973' },
                { specChemProduct: 'SpecTilt WB', ID: '7974' },
                { specChemProduct: 'SpecWeld', ID: '7546' },
                { specChemProduct: 'Strong Bond', ID: '7547' },
                { specChemProduct: 'StuccoBond', ID: '7548' },
                { specChemProduct: 'Super Por-Rok', ID: '7542' },
                { specChemProduct: 'Super SpecPlug', ID: '7976' },
                { specChemProduct: 'Surface Grip', ID: '7527' },
                { specChemProduct: 'Surface Shine', ID: '7528' },
                { specChemProduct: 'Surface Shine Brown', ID: '9184' },
                { specChemProduct: 'Surface Shine Brown EX', ID: '7529' },
                { specChemProduct: 'Surface Shine EX', ID: '7531' },
                { specChemProduct: 'Surface Shine Gray', ID: '7530' },
                { specChemProduct: 'Surface Shine Gray EX', ID: '7532' },
                { specChemProduct: 'Surface Shine WB', ID: '7534' },
                { specChemProduct: 'Total Patch', ID: '8180' },
                { specChemProduct: 'TriAct', ID: '7978' },
                { specChemProduct: 'UW Grout', ID: '7543' },
                { specChemProduct: 'V-Release', ID: '7981' },
                { specChemProduct: 'Versa Etch', ID: '7979' },
                { specChemProduct: 'Xylene', ID: '7503' },
            ],
        };
    },
    mounted() {
        this.fetchStates();
    },
    methods: {
        async fetchStates() {
            try {
                const response = await fetch("https://specchem.com/wp-json/wp/v2/dot_approvals?per_page=100");
                const data = await response.json();
                this.states = data;
            } catch (error) {
                console.error("An error occurred while fetching the states:", error);
            }
        },
        async fetchProducts() {
            if (!this.selectedState) return;

            try {
                const response = await fetch(`https://specchem.com/wp-json/wp/v2/dot_approvals/${this.selectedState}`);
                const data = await response.json();
                let productsHtml = data.acf.dot_approved_products;

                // Convert HTML string to DOM elements to extract product names
                const parser = new DOMParser();
                const doc = parser.parseFromString(productsHtml, 'text/html');
                const productNames = Array.from(doc.querySelectorAll('p')) // Select all <p> tags
                    .map(elem => elem.textContent.trim());

                // Find corresponding IDs from SpecChemProductIDs array
                this.products = productNames.map(name => {
                    const product = this.SpecChemProductIDs.find(prod => prod.specChemProduct === name);
                    return product ? { name, ID: product.ID } : null;
                }).filter(Boolean); // Remove any null entries

            } catch (error) {
                console.error("An error occurred while fetching the products:", error);
            }
        },
        async selectProduct(event) {
            // Find the target element (you may need to adjust this depending on your HTML structure)
            let element = event.target;
            while (element && !element.getAttribute('data-product-id')) {
                element = element.parentElement;
            }

            if (!element) {
                console.error('Product element not found');
                return;
            }

            const productID = element.getAttribute('data-product-id');

            // Find the product name from the SpecChemProductIDs array using the productID
            const productObj = this.SpecChemProductIDs.find(p => p.ID === productID);
            const productName = productObj ? productObj.specChemProduct : 'Product Name';

            // Call the existing GetProduct method with the found ID and product name
            await this.GetProduct(productID, productName);
        },
        async GetProduct(productID, productName) {
            this.showProductList = false;
            console.log('Starting GetProduct', productID, productName);

            try {
                const url = `https://us-central1-specchem-pwa.cloudfunctions.net/productsProxy?productId=${productID}`;
                const response = await axios.get(url);
                console.log('Received response', response);
                this.productData = [response.data];
                if (this.productData && this.productData[0]) {
                    console.log(this.productData[0]);
                    this.productName = this.productData[0].name;
                    this.productDescription = this.productData[0].short_description;

                    const acf = this.productData[0].acf;
                    this.productData[0].sheets = [
                            { name: 'Tech Data Sheet', link: acf.tech_data_sheet },
                            { name: 'Safety Data Sheet', link: acf.safety_data_sheet },
                            { name: 'Sell Sheet', link: acf.sell_sheet },
                            { name: 'Spanish TDS', link: acf.tds_esp },
                        ].filter(sheet => sheet.link)
                        .map((sheet, index) => ({ ...sheet, circlePosition: index + 1 }));

                    this.productSelected = true;
                } else {
                    console.error('No product data returned from API');
                }
            } catch (error) {
                console.log('Error during GetProduct', error);
                // If you have a handleApiError method, make sure to define it in the methods object
                if (this.handleApiError) {
                    this.handleApiError(error);
                }
            } finally {
                console.log('Done processing product!');
            }
        }
    },
};
</script>